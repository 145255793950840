<template>
  <div class="home">
    <Header title="計測リスト" />
    <b-container class="mt-2">
      <b-table-simple hover striped bordered responsive>
        <b-thead head-variant="dark">
          <b-tr>
            <b-th>計測日時</b-th>
            <b-th>マーカー構成</b-th>
            <b-th></b-th>
          </b-tr>
        </b-thead>
        <tbody>
          <template v-for="ms in measurements">
            <b-tr :key="`${ms.measurementId}`">
              <b-td>
                {{ ms.activatedAt | timeYmdTms }}
              </b-td>
              <b-td>
                <template v-for="(markerId, index) of ms.markerIds">
                  <MarkerId :key="index" :markerId="markerId"></MarkerId>
                </template>
              </b-td>

              <b-td>
                <b-button :to="{ name: 'Measurement', params: { measurementId: ms.measurementId } }"></b-button>
              </b-td>
            </b-tr>
          </template>
        </tbody>
      </b-table-simple>


      <b-button variant="light" :to="{ name: 'MeasurementCreate' }">計測追加</b-button>

    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import { format } from 'date-fns'
import Header from "@/components/Header";
import Head from "@/mixins/Head";
import MarkerId from "@/components/MarkerId";

export default {
  name: "MeasurementList",
  components: { Header, MarkerId },
  mixins: [Head],
  head: {
    ...Head.head,
    title: {
      ...Head.head.title,
      inner: "計測リスト",
    },
  },
  async mounted() {
    this.$store.dispatch("uiLock/incrementLoadingCount");
    try {
      this.rawMeasurementList = await this.$store.dispatch("zumen/getMeasurementList", {})
    }
    finally {
      this.$store.dispatch("uiLock/decrementLoadingCount");
    }
  },
  data() {
    return {
      rawMeasurementList: null
    };
  },
  filters: {
    timeYmdTms(unixTimeMillisec) {
      if (!Number.isFinite(unixTimeMillisec)) { return null }
      return format(new Date(unixTimeMillisec), 'yyyy-MM-dd HH:mm')
    }
  },
  computed: {
    measurements() {
      if (Array.isArray(this.rawMeasurementList)) {
        return this.rawMeasurementList.map(i => ({
          measurementId: i.measurementId,
          activatedAt: i.activatedAt,
          markerIds: i.markerIds.sort((m1, m2) => m1.localeCompare(m2)),
        })).sort((i1, i2) => -(i1.activatedAt - i2.activatedAt))
      } else {
        return []
      }
    }
  },
  methods: {
  },
};
</script>
