<template>
  <div ref="widthMeasure" width="100%"></div>
</template>

<script>

export default {
  name: "DisplaySizeMeasure",
  components: {},
  mixins: [],
  data() {
    return {
      unwatch: function () { },
      width: null,
      top: null,
    };
  },
  computed: {
  },
  async mounted() {
    this.unwatch = this.$watch(
      () => (JSON.stringify({ width: this.width, top: this.top })),
      () => {
        this.$emit('resize', { width: this.width, top: this.top })
      }
    )
    this.resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target == this.$refs.widthMeasure) {
          this.width = entry.contentRect.width;
          this.top = entry.target.getBoundingClientRect().top
        }
      }
    });
    this.resizeObserver.observe(this.$refs.widthMeasure);
  },
  beforeDestroy() {
    this.unwatch()
    this.unwatch = function () { }
    this.resizeObserver.disconnect();
  },
  methods: {
  },
  watch: {

  }
};
</script>
