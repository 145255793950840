<template>
  <div class="home">
    <Header title="デバイスリスト" />
    <b-container class="mt-2">
      <b-table-simple hover striped bordered responsive>
        <b-thead head-variant="dark">
          <b-tr>
            <b-th></b-th>
            <b-th>デバイスコード</b-th>
            <b-th>登録日時</b-th>
            <b-th>設定</b-th>
            <b-th></b-th>
          </b-tr>
        </b-thead>
        <tbody>
          <template v-if="commonDevice">
            <b-tr>
              <b-td colspan="3">
                共通設定
              </b-td>
              <b-td>
                <pre>{{ commonDevice.appSettingYaml }}</pre>
              </b-td>
              <b-td>
                <b-button @click="onClickStartEditCommon">設定編集</b-button>
              </b-td>
            </b-tr>
          </template>

          <template v-for="dev in devices">
            <b-tr :key="`dev_${dev.deviceId}`">
              <b-td>
                <b-form-radio v-model="mainDeviceId" :value="dev.deviceId"></b-form-radio>
              </b-td>
              <b-td>
                {{ dev.deviceCode }}
              </b-td>
              <b-td>
                {{ dev.createdAt | timeYmdTms }}
              </b-td>
              <b-td>
                <pre>{{ dev.appSettingYaml }}</pre>

              </b-td>
              <b-td>
                <b-button @click="onClickStartEdit(dev.deviceId)">設定編集</b-button>
              </b-td>
            </b-tr>
          </template>
        </tbody>
      </b-table-simple>

    </b-container>
    <b-modal v-model="displayModalEditYaml" :title="modalEditYamlTitle"
      :ok-disabled="modalEditYamlError === null ? false : true" @ok="onOkModalEditYaml">
      <b-form-textarea v-model="modalEditYamlInput" max-rows="20" :state="modalEditYamlError === null ? null : false"
        @input="onModalEditYamlInputChange">
      </b-form-textarea>
      <template v-if="modalEditYamlError != null">
        <pre>{{ modalEditYamlError }}</pre>
      </template>

    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import { format } from 'date-fns'
import Header from "@/components/Header";
import Head from "@/mixins/Head";
import yaml from "js-yaml";
import v8n from "v8n";

export default {
  name: "DeviceList",
  components: { Header, },
  mixins: [Head],
  head: {
    ...Head.head,
    title: {
      ...Head.head.title,
      inner: "デバイスリスト",
    },
  },
  async mounted() {
    this.$store.dispatch("uiLock/incrementLoadingCount");
    try {
      await this.loadDeviceList()
      this.mainDeviceId = this.$store.getters['device/currentDeviceId']
    }
    finally {
      this.$store.dispatch("uiLock/decrementLoadingCount");
    }
  },
  data() {
    return {
      commonDevice: null,
      rawDevices: null,

      mainDeviceId: null,

      displayModalEditYaml: false,
      modalEditYamlTitle: null,
      modalEditYamlOperation: null,
      modalEditYamlInput: null,
      modalEditYamlError: null,
    };
  },
  computed: {
    devices() {
      if (Array.isArray(this.rawDevices)) {
        return this.rawDevices.map(i => ({
          ...i,
        })).sort((f1, f2) => f1.deviceCode.localeCompare(f2.deviceCode))
      } else {
        return []
      }
    }
  },
  filters: {
    timeYmdTms(unixTimeMillisec) {
      if (!Number.isFinite(unixTimeMillisec)) { return null }
      return format(new Date(unixTimeMillisec), 'yyyy-MM-dd HH:mm')
    }
  },
  methods: {
    async loadDeviceList() {
      const { devices, commonDevice } = await this.$store.dispatch("device/getDeviceList", {})
      this.rawDevices = devices
      this.commonDevice = commonDevice
    },
    onClickStartEditCommon() {
      if (!this.commonDevice) { return }
      this.modalEditYamlInput = this.commonDevice.appSettingYaml
      this.modalEditYamlError = null
      this.modalEditYamlOperation = {
        checkError: (info) => {
          if (v8n().schema({
            appSetting: v8n().object()
          }).test(info)) {
            return null
          } else {
            return 'appSetting:{...} が必要です。'
          }
        },
        save: (info, yamlStr) => {
          (async () => {
            try {
              await this.saveDeviceAppSetting(this.commonDevice.deviceId, yamlStr)
            } catch (err) {
              this.$store.dispatch("app/updateAppError", { error: err, });
            }
          })()
        }
      }
      this.modalEditYamlTitle = `共通 設定編集`
      this.displayModalEditYaml = true

    },
    onClickStartEdit(deviceId) {
      const device = this.rawDevices.find(d => d.deviceId == deviceId)
      if (!device) { return }

      this.modalEditYamlInput = device.appSettingYaml
      this.modalEditYamlError = null
      this.modalEditYamlOperation = {
        checkError: (info) => {
          if (v8n().schema({
            appSetting: v8n().object()
          }).test(info)) {
            return null
          } else {
            return 'appSetting:{...} が必要です。'
          }
        },
        save: (info, yamlStr) => {
          (async () => {
            try {
              await this.saveDeviceAppSetting(device.deviceId, yamlStr)
            } catch (err) {
              this.$store.dispatch("app/updateAppError", { error: err, });
            }
          })()
        }
      }
      this.modalEditYamlTitle = `デバイス[${device.deviceCode}] 設定編集`
      this.displayModalEditYaml = true
    },

    onModalEditYamlInputChange() {
      try {
        const info = yaml.load(this.modalEditYamlInput)
        if (typeof (this.modalEditYamlOperation?.checkError) == 'function') {
          this.modalEditYamlError = this.modalEditYamlOperation.checkError(info)
        } else {
          this.modalEditYamlError = '????'
        }
      } catch (error) {
        if (error?.mark?.snippet && error?.reason) {
          this.modalEditYamlError = error?.reason + "\n" + error?.mark?.snippet
        } else {
          this.modalEditYamlError = error.toString()
        }
      }
    },
    onOkModalEditYaml(e) {
      console.log('onOkModalEditYaml')
      if (!(typeof (this.modalEditYamlOperation?.checkError) == 'function' && typeof (this.modalEditYamlOperation?.save) == 'function')) {
        throw new Error('modalEditYamlOperation 設定エラー')
      }
      const info = (() => {
        try {
          return yaml.load(this.modalEditYamlInput)
        }
        catch (error) {
          return null
        }
      })()
      if (info != null && this.modalEditYamlOperation.checkError(info) == null) {
        this.modalEditYamlOperation.save(info, this.modalEditYamlInput)
      } else {
        e.preventDefault()
      }
    },

    async saveDeviceAppSetting(deviceId, appSettingYaml) {
      this.$store.dispatch("uiLock/incrementLoadingCount");
      try {
        await this.$store.dispatch("device/modifyDevice", { deviceId, appSettingYaml })

        await this.loadDeviceList()
      }
      finally {
        this.$store.dispatch("uiLock/decrementLoadingCount");
      }
    }
  },
  watch: {
    async mainDeviceId() {
      console.log('mainDeviceId', this.mainDeviceId)
      await this.$store.dispatch("device/setCurrentDeviceId", { deviceId: this.mainDeviceId })
    }
  }
};
</script>
