<template>
  <div class="home">
    <Header :title="`平面図 [${floorName ? floorName : '...'}] - マーカー測量修正`" />
    <b-container class="mt-2 border">
      <b-row>
        <b-col class="pr-0" cols="4">
          <b-overlay :show="operation != null" rounded="sm"
            :style="{ height: `${floorFigureCanvasHeight}px`, overflowY: operation != null ? 'hidden' : 'auto' }">
            <div class="mb-4">
              <h4>マーカー</h4>
              <b-button variant="light" size="sm" class="mr-2" @click="onClickStartEditMarker">変更</b-button>
              <b-button variant="light" size="sm" @click="onClickStartAddMarkerPos">図面でマーカー位置追加</b-button>
              <pre class="border">{{ markerYaml }}</pre>
            </div>
            <div class="mb-4">
              <h4>計測ゼロ点</h4>
              <b-button variant="light" size="sm" class="mr-2" @click="onClickStartEditOriginYaml">変更</b-button>
              <b-button variant="light" size="sm" @click="onClickStartInputOrigin">図面で入力</b-button>
              <pre class="border">{{ originYaml }}</pre>

            </div>
            <div class="mb-4">
              <h4>X-Y軸,スケール</h4>
              <b-button variant="light" size="sm" class="mr-2" @click="onClickStartEditAxis">変更</b-button>
              <b-button variant="light" size="sm" @click="onClickStartInputAxisX">図面でX軸入力</b-button>
              <b-button variant="light" size="sm" @click="onClickStartInputAxisY">図面でY軸入力</b-button>
              <pre class="border">{{ axisYaml }}</pre>
            </div>


            <template #overlay>
              <div class="text-center">

                <div class="mb-3">図面をクリックしてください</div>

                <b-button ref="cancel" variant="outline-primary" size="sm" aria-describedby="cancel-label"
                  @click="onClickInputCancel">
                  キャンセル
                </b-button>
              </div>
            </template>
          </b-overlay>
        </b-col>

        <b-col cols="8" class="px-0">
          <DisplaySizeMeasure @resize="onResizeFloorFigureMeasure($event)"></DisplaySizeMeasure>
          <FloorFigureCanvas :floorId="floorId" :width="floorFigureCanvasWidth" :height="floorFigureCanvasHeight"
            :transfer="canvasTransfer" @click="onClickFloorFigure($event)" @mousemove="onMousemoveFloorFigure($event)">
            <template v-slot:default="p">
              <!-- マーカー定義 -->
              <g style="display:none">
                <g id="marker">
                  <line x1="-5" y1="-20" x2="-5" y2="20" stroke="white" stroke-width="6" stroke-linecap="round" />
                  <circle cx="0" cy="0" r="5" fill="none" stroke="white" stroke-width="6" />

                  <line x1=" -5" y1="-20" x2="-5" y2="20" stroke="darkorange" stroke-width="3" />
                  <circle cx="0" cy="0" r="5" fill="none" stroke="darkorange" stroke-width="3" />
                </g>
              </g>
              <template v-if="canvasTransfer && Array.isArray(markerInfo)">
                <template v-for="(m, mIndex) in markerInfo">
                  <g :key="mIndex">
                    <template v-if="m.meter">
                      <g
                        :transform="p.transformFromMeter({ x: m.meter.x, y: m.meter.y }, { x: m.meter.nx, y: m.meter.ny })">
                        <use href="#marker" />
                      </g>
                    </template>
                    <template v-else-if="m.pixel">
                      <g
                        :transform="p.transformFromPix({ x: m.pixel.x, y: m.pixel.y }, { x: m.pixel.nx, y: m.pixel.ny })">
                        <use href="#marker" />
                      </g>
                    </template>
                  </g>
                </template>
              </template>
            </template>
          </FloorFigureCanvas>
          <div style="position:absolute; top:0px; left:0px; background-color: white; opacity: 80%;">
            <div>Pixel:({{ mousePixelX | num1 }}, {{ mousePixelY | num1 }})</div>
            <div>Meter:({{ mouseMeterX | num2 }}, {{ mouseMeterY | num2 }})</div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <div ref="footer" class="fixed-bottom">
      <b-container class="mt-2 py-2 bg-white shadow ">
        <div class="d-flex">
          <b-button class="" @click="onClickRemove">削除</b-button>

          <b-button class="ml-auto" @click="onClickCancel">キャンセル</b-button>
          <b-button class="ml-3" @click="onClickSave">保存</b-button>
        </div>
      </b-container>
    </div>
    <b-modal v-model="displayModalEditYaml" :title="modalEditYamlTitle"
      :ok-disabled="modalEditYamlError === null ? false : true" @ok="onOkModalEditYaml">
      <b-form-textarea v-model="modalEditYamlInput" max-rows="20" :state="modalEditYamlError === null ? null : false"
        @input="onModalEditYamlInputChange">
      </b-form-textarea>
      <template v-if="modalEditYamlError != null">
        <pre>{{ modalEditYamlError }}</pre>
      </template>

    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header";
import Head from "@/mixins/Head";
import FloorFigureCanvas from "@/components/FloorFigureCanvas";
import DisplaySizeMeasure from "@/components/DisplaySizeMeasure";
import yaml from "js-yaml";
import v8n from "v8n";
import numeral from "numeral"
import { zip as lodashZip } from "lodash"

export default {
  name: "FloorPhysicsAssociationList",
  components: { Header, FloorFigureCanvas, DisplaySizeMeasure },
  mixins: [Head],
  head: {
    ...Head.head,
    title: {
      ...Head.head.title,
      inner: "マーカー測量",
    },
  },
  props: ["floorId", "associationId"],
  async mounted() {
    this.$store.dispatch("uiLock/incrementLoadingCount");
    try {
      this.onWindowResize()
      window.addEventListener('resize', this.onWindowResize)

      this.footerHeight = this.$refs.footer.clientHeight

      const [floorFigure, physicAssoc] = await Promise.all([
        (async () => {
          const floorFigure = await this.$store.dispatch("zumen/getFloorFigure", { floorId: this.floorId })
          return floorFigure
        })(),
        (async () => {
          if (this.isCreateNew) {
            return null
          } else {
            const physicAssoc = await this.$store.dispatch("zumen/getPhysicsAssociation", { floorId: this.floorId, associationId: this.associationId })
            return physicAssoc
          }
        })(),
      ])

      this.floorName = floorFigure.floorName

      if (physicAssoc) {
        const pxFromMt = physicAssoc.transfer.pxFromMt
        this.setOriginInfo({ x: pxFromMt.tX, y: pxFromMt.tY })

        const axisXMeter = physicAssoc.editorInfo?.manualMeasured?.axisXMeter ?? 1.0
        const axisYMeter = physicAssoc.editorInfo?.manualMeasured?.axisYMeter ?? 1.0

        this.setAxisInfo(
          { x: pxFromMt.rowX.x * axisXMeter, y: pxFromMt.rowX.y * axisXMeter, meter: axisXMeter },
          { x: pxFromMt.rowY.x * axisYMeter, y: pxFromMt.rowY.y * axisYMeter, meter: axisYMeter },
        )
        const markers = [].concat(
          physicAssoc.markers.map(m => {
            const match = m.markerId.match(/^arUco4x4_(?<id>\d+)$/)
            const markerId = match ? parseInt(match.groups.id) : m.markerId

            if (m.locationPos3Normal && m.definition.meter && m.definition.meter.associationId == physicAssoc.associationId) {
              const pos = m.locationPos3Normal.pos
              const n = m.locationPos3Normal.normal
              return {
                id: markerId,
                meter: {
                  x: pos.x, y: pos.y, z: pos.z,
                  nx: n.x, ny: n.y
                }
              }
            } else if (m.locationPos3Normal && m.definition.pixel) {
              const pos = m.locationPos3Normal.pos
              const n = m.locationPos3Normal.normal
              return {
                id: markerId,
                pixel: {
                  x: pos.x, y: pos.y,
                  nx: n.x, ny: n.y
                },
                meterZ: pos.z,
              }
            }
            return null
          }),
          (physicAssoc.editorInfo?.manualMeasured?.invalidMarkers ?? [])
        )
        this.setMarkerInfo(markers)
      } else {
        this.setOriginInfo({ x: floorFigure.figureSizePx.width * 0.5, y: floorFigure.figureSizePx.height * 0.5 })
        const axisLen = Math.min(floorFigure.figureSizePx.width, floorFigure.figureSizePx.height) * 0.25
        this.setAxisInfo(
          { x: -axisLen * 0.25, y: 0, meter: 2.0 },
          { x: 0, y: axisLen * 0.25, meter: 2.0 },
        )
        this.setMarkerInfo([])
      }
    }
    finally {
      this.$store.dispatch("uiLock/decrementLoadingCount");
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onWindowResize)
  },
  filters: {
    num1(v) {
      if (!Number.isFinite(v)) { return null }
      return numeral(v).format('+0.0')
    },
    num2(v) {
      if (!Number.isFinite(v)) { return null }
      return numeral(v).format('+0.00')
    }
  },
  data() {
    return {
      floorName: null,
      innerWidth: null, innerHeight: null,
      floorFigureCanvasTop: null, floorFigureCanvasWidth: null,
      footerHeight: null,

      mousePixelX: null, mousePixelY: null,
      mouseMeterX: null, mouseMeterY: null,

      originInfo: null, originYaml: null,
      axisXInfo: null, axisYInfo: null, axisYaml: null,
      markerInfo: null, markerYaml: null,

      operation: null,
      displayModalEditYaml: false,
      modalEditYamlTitle: null,
      modalEditYamlOperation: null,
      modalEditYamlInput: null,
      modalEditYamlError: null,
    };
  },
  computed: {
    isCreateNew() {
      return this.associationId == '*new*'
    },
    floorFigureCanvasHeight() {
      if (Number.isFinite(this.innerHeight) && Number.isFinite(this.floorFigureCanvasTop) && Number.isFinite(this.footerHeight)) {
        return this.innerHeight - this.floorFigureCanvasTop - this.footerHeight
      } else {
        return 0
      }
    },

    canvasTransfer() {
      if (this.originInfo && this.axisXInfo && this.axisYInfo) {
        return {
          originAxis: {
            origin: { x: this.originInfo.x, y: this.originInfo.y },
            axisX: { x: this.axisXInfo.x, y: this.axisXInfo.y, meter: this.axisXInfo.meter },
            axisY: { x: this.axisYInfo.x, y: this.axisYInfo.y, meter: this.axisYInfo.meter },
          }
        }
      } else {
        return null
      }
    },
  },
  methods: {
    onWindowResize() {
      this.innerWidth = window.innerWidth;
      this.innerHeight = window.innerHeight;
    },
    onResizeFloorFigureMeasure(e) {
      this.floorFigureCanvasTop = e.top
      this.floorFigureCanvasWidth = e.width
    },

    onMousemoveFloorFigure(e) {
      const { imagePixelX, imagePixelY, meterX, meterY } = e
      this.mousePixelX = imagePixelX
      this.mousePixelY = imagePixelY
      this.mouseMeterX = meterX
      this.mouseMeterY = meterY
    },

    onClickFloorFigure(e) {
      const { imagePixelX, imagePixelY } = e
      if (this.operation != null && typeof (this.operation.onClick) == 'function') {
        this.operation.onClick({ imagePixelX, imagePixelY })
      }
    },
    onClickInputCancel() {
      this.operation = null
    },

    onModalEditYamlInputChange() {
      try {
        const info = yaml.load(this.modalEditYamlInput)
        if (typeof (this.modalEditYamlOperation?.checkError) == 'function') {
          this.modalEditYamlError = this.modalEditYamlOperation.checkError(info)
        } else {
          this.modalEditYamlError = '????'
        }
      } catch (error) {
        if (error?.mark?.snippet && error?.reason) {
          this.modalEditYamlError = error?.reason + "\n" + error?.mark?.snippet
        } else {
          this.modalEditYamlError = error.toString()
        }
      }
    },
    onOkModalEditYaml(e) {
      console.log('onOkModalEditYaml')
      if (!(typeof (this.modalEditYamlOperation?.checkError) == 'function' && typeof (this.modalEditYamlOperation?.save) == 'function')) {
        throw new Error('modalEditYamlOperation 設定エラー')
      }
      const info = (() => {
        try {
          return yaml.load(this.modalEditYamlInput)
        }
        catch (error) {
          return null
        }
      })()
      if (info != null && this.modalEditYamlOperation.checkError(info) == null) {
        this.modalEditYamlOperation.save(info)
      } else {
        e.preventDefault()
      }
    },

    onClickStartEditOriginYaml() {
      this.modalEditYamlInput = this.originYaml
      this.modalEditYamlError = null
      this.modalEditYamlOperation = {
        checkError: (info) => {
          if (v8n().schema({
            origin: v8n().schema({
              x: v8n().number(),
              y: v8n().number(),
            })
          }).test(info)) {
            return null
          } else {
            return 'origin:{ x:number, y:number } が必要です。'
          }
        },
        save: (info) => {
          const { origin: { x, y } } = info
          this.setOriginInfo({ x, y })
        }
      }
      this.modalEditYamlTitle = '計測ゼロ点'
      this.displayModalEditYaml = true
    },
    setOriginInfo(originInfo) {
      v8n().schema({
        x: v8n().number(),
        y: v8n().number(),
      }).check(originInfo)
      const { x, y } = originInfo
      this.originInfo = { x, y }
      this.originYaml = yaml.dump({ origin: this.originInfo })
    },
    onClickStartInputOrigin() {
      this.operation = {
        onClick: ({ imagePixelX, imagePixelY }) => {
          this.setOriginInfo({
            x: Math.round(imagePixelX * 10) / 10,
            y: Math.round(imagePixelY * 10) / 10,
          })
          this.onClickInputCancel()
        }
      }
    },

    onClickStartEditAxis() {
      this.modalEditYamlInput = this.axisYaml
      this.modalEditYamlError = null
      this.modalEditYamlOperation = {
        checkError: (info) => {
          const axisValidator = v8n().schema({
            x: v8n().number(),
            y: v8n().number(),
            meter: v8n().number(),
          })
          if (!v8n().schema({
            axisX: axisValidator,
            axisY: axisValidator,
          }).test(info)) {
            return ['axisX:{ x:number, y:number, meter:number },', 'axisY:{ x:number, y:number, meter:number }', 'が必要です。'].join("\n")
          }
          return null
        },
        save: (info) => {
          const axisXInfo = (() => {
            const { axisX: { x, y, meter } } = info
            return { x, y, meter }
          })()
          const axisYInfo = (() => {
            const { axisY: { x, y, meter } } = info
            return { x, y, meter }
          })()
          this.setAxisInfo(axisXInfo, axisYInfo)
        }
      }
      this.modalEditYamlTitle = 'X-Y軸,スケール'
      this.displayModalEditYaml = true
    },
    setAxisInfo(axisXInfo, axisYInfo) {
      const axisValidator = v8n().schema({
        x: v8n().number(),
        y: v8n().number(),
        meter: v8n().number(),
      })
      axisValidator.check(axisXInfo)
      axisValidator.check(axisYInfo)
      this.axisXInfo = { x: axisXInfo.x, y: axisXInfo.y, meter: axisXInfo.meter }
      this.axisYInfo = { x: axisYInfo.x, y: axisYInfo.y, meter: axisYInfo.meter }
      this.axisYaml = yaml.dump({ axisX: this.axisXInfo, axisY: this.axisYInfo })
    },
    onClickStartInputAxisX() {
      this.operation = {
        onClick: ({ imagePixelX, imagePixelY }) => {
          this.setAxisInfo(
            {
              x: Math.round((imagePixelX - this.originInfo.x) * 10) / 10,
              y: Math.round((imagePixelY - this.originInfo.y) * 10) / 10,
              meter: this.axisXInfo.meter,
            },
            this.axisYInfo
          )
          this.onClickInputCancel()
        }
      }
    },
    onClickStartInputAxisY() {
      this.operation = {
        onClick: ({ imagePixelX, imagePixelY }) => {
          this.setAxisInfo(
            this.axisXInfo,
            {
              x: Math.round((imagePixelX - this.originInfo.x) * 10) / 10,
              y: Math.round((imagePixelY - this.originInfo.y) * 10) / 10,
              meter: this.axisYInfo.meter,
            }
          )
          this.onClickInputCancel()
        }
      }
    },


    onClickStartEditMarker() {
      this.modalEditYamlInput = this.markerYaml
      this.modalEditYamlError = null
      this.modalEditYamlOperation = {
        checkError: (info) => {
          console.log('info', info)
          const invalidSchema = {
            pixel: v8n().undefined(), meterZ: v8n().undefined(),
            meter: v8n().undefined(),
          }
          if (!v8n().schema({
            markers: v8n().array()
              .every.schema({
                id: v8n().not.undefined()
              })
              .every.passesAnyOf(
                v8n().schema({
                  ...invalidSchema,
                  pixel: v8n().schema({
                    x: v8n().number(), y: v8n().number(),
                    nx: v8n().number(), ny: v8n().number(),
                  }),
                  meterZ: v8n().number(),
                }),
                v8n().schema({
                  ...invalidSchema,
                  meter: v8n().schema({
                    x: v8n().number(), y: v8n().number(), z: v8n().number(),
                    nx: v8n().number(), ny: v8n().number(),
                  }),
                }),
              )
          }).test(info)) {
            return [
              'markers:Array<{',
              ' id: number,',
              ' pixel:{ x:number, y:number, nx:number, ny:number }, ',
              ' meterZ:number',
              '} | {',
              ' id: number,',
              ' meter:{ x:number, y:number, z:number, nx:number, ny:number }',
              '}>',
              'が必要です。'
            ].join("\n")
          }
          return null
        },
        save: (info) => {
          this.setMarkerInfo(info.markers)
        }
      }
      this.modalEditYamlTitle = 'マーカー'
      this.displayModalEditYaml = true
    },
    setMarkerInfo(markerInfo) {

      const checkedMarkers = []
      for (const m of markerInfo) {
        const errors = []
        if (!(Number.isSafeInteger(m.id) && m.id >= 0)) {
          errors.push('id不正')
        }
        if (m.meter) {
          const norm2 = m.meter.nx * m.meter.nx + m.meter.ny * m.meter.ny
          if (norm2 < 1e-3) { errors.push('方向なし') }
        } else if (m.pixel) {
          const norm2 = m.pixel.nx * m.pixel.nx + m.pixel.ny * m.pixel.ny
          if (norm2 < 1e-3) { errors.push('方向なし') }
        }
        if (errors.length == 0 && checkedMarkers.filter(m => (m?.errors ?? []).length == 0).some(vm => vm.id == m.id)) {
          errors.push(`id重複`)
        }

        const marker = (() => {
          if (m.meter) {
            return {
              id: m.id,
              meter: {
                x: m.meter.x, y: m.meter.y, z: m.meter.z,
                nx: m.meter.nx, ny: m.meter.ny,
              }
            }
          } else if (m.pixel) {
            return {
              id: m.id,
              pixel: {
                x: m.pixel.x, y: m.pixel.y,
                nx: m.pixel.nx, ny: m.pixel.ny,
              },
              meterZ: m.meterZ
            }
          } else {
            throw new Error(`invalid marker [${JSON.stringify(m, null, 2)}]`)
          }
        })()
        checkedMarkers.push({
          ...marker,
          ...(errors.length == 0 ? { errors: (void 0) } : { errors })
        })
      }
      this.markerInfo = checkedMarkers
      this.markerYaml = yaml.dump({ markers: this.markerInfo })
    },

    onClickStartAddMarkerPos() {
      this.operation = {
        onClick: ({ imagePixelX, imagePixelY }) => {
          this.setMarkerInfo(
            this.markerInfo.concat([{
              id: `入力中 #${this.markerInfo.length + 1}`,
              pixel: {
                x: Math.round(imagePixelX * 10) / 10,
                y: Math.round(imagePixelY * 10) / 10,
                nx: 1, ny: 0
              },
              meterZ: 1.0
            }])
          )
          this.onClickInputCancel()
        }
      }
    },

    onClickCancel() {
      this.$router.go(-1);
    },
    async onClickSave() {
      this.$store.dispatch("uiLock/incrementLoadingCount");
      try {
        await this.$store.dispatch("zumen/makeOrModifyPhysicsAssociation", {
          floorId: this.floorId,
          associationId: this.isCreateNew ? null : this.associationId,
          origin: { x: this.originInfo.x, y: this.originInfo.y },
          axisX: { x: this.axisXInfo.x, y: this.axisXInfo.y, meter: this.axisXInfo.meter },
          axisY: { x: this.axisYInfo.x, y: this.axisYInfo.y, meter: this.axisYInfo.meter },
          markers: this.markerInfo.filter(m => (m?.errors ?? []).length == 0),
          invalidMarkers: this.markerInfo.filter(m => (m?.errors ?? []).length != 0),
        })

        this.$router.go(-1);
      }
      finally {
        this.$store.dispatch("uiLock/decrementLoadingCount");
      }
    },

    async onClickRemove() {
      this.$store.dispatch("uiLock/incrementLoadingCount");
      try {
        if (!this.isCreateNew) {
          await this.$store.dispatch("zumen/removePhysicsAssociation", {
            floorId: this.floorId,
            associationId: this.associationId,
          })
        }

        this.$router.go(-1);
        this.$router.replace({ name: 'FloorPhysicsAssociationList', params: { floorId: this.floorId } })
      }
      finally {
        this.$store.dispatch("uiLock/decrementLoadingCount");
      }

    }
  },
};
</script>
