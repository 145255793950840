const AmazonCognitoIdentity = require("amazon-cognito-identity-js")
import axios from "axios";
import AWS from "aws-sdk";
import { ApiError } from "@/utils/errors";

const BASE_URL = process.env.VUE_APP_BASE_URL;
const REGION = 'ap-northeast-1'
const USER_POOL_ID = process.env.VUE_APP_USER_POOL_ID;
const IDENTITY_POOL_ID = process.env.VUE_APP_IDENTITY_POOL_ID;
const CLIENT_ID = process.env.VUE_APP_CLIENT_ID;

const userPool = new AmazonCognitoIdentity.CognitoUserPool({
  UserPoolId: USER_POOL_ID,
  ClientId: CLIENT_ID,
})

const set = property => (state, payload) => (state[property] = payload)
const get = property => (state) => (state[property])

export default {
  namespaced: true,
  modules: {
  },
  state: {
    isRequireNewPassword: false,

    session: null,
    sessionPromise: null,

    getValidIdTokenPromiseResolves: [],
    getValidIdTokenPromise: null,
  },
  getters: {
    isLogout: (state) => { return state.session === null },
    username: (state) => { return state.session ? state.session.idToken.payload['cognito:username'] : null },
    isAdmin: (state) => {
      const groups = (state.session ? state.session.idToken.payload['cognito:groups'] : [])
      return Array.isArray(groups) && groups.includes('Admin')
    },

    isRequireNewPassword: get('isRequireNewPassword'),
  },
  mutations: {
    setRequireNewPassword: set('isRequireNewPassword'),

    setSessionPromise: set('sessionPromise'),

    setSession: (state, session) => {
      state.session = session
      if (session) {
        for (const resolve of state.getValidIdTokenPromiseResolves) {
          resolve(session.getIdToken().getJwtToken())
        }
        state.getValidIdTokenPromiseResolves = []
        state.getValidIdTokenPromise = null
      }
    },
    setWaitingValidIdToken: (state, { getValidIdTokenPromise, resolve }) => {
      state.getValidIdTokenPromiseResolves.push(resolve)
      state.getValidIdTokenPromise = getValidIdTokenPromise
    }

  },
  actions: {
    async checkSession({ state, commit, dispatch }) {
      if (state.sessionPromise == null) {
        commit('setSessionPromise', new Promise((resolve, reject) => {
          try {
            const cognitoUser = userPool.getCurrentUser()
            if (cognitoUser == null) {
              commit('setSession', null)
              resolve(null)
            }
            cognitoUser.getSession(function (err, session) {
              if (err) {
                commit('setSession', null)
                resolve(null)
              } else {
                AWS.config.region = REGION
                AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                  IdentityPoolId: IDENTITY_POOL_ID,
                  Logins: {
                    // Change the key below according to the specific region your user pool is in.
                    [`cognito-idp.${REGION}.amazonaws.com/${USER_POOL_ID}`]: session.getIdToken().getJwtToken(),
                  },
                });

                commit('setSession', session)
                resolve(session)
              }
            });
          } catch (err) {
            reject(err)
          }
        }))
      }
      await state.sessionPromise
      if (!state.session) {
        return
      }

      if (state.session && state.session.isValid()) {
        return
      }
      commit('setSessionPromise', new Promise((resolve, reject) => {
        try {
          var cognitoUser = new AmazonCognitoIdentity.CognitoUser({
            Username: state.session.idToken.payload.email,
            Pool: userPool,
          });

          cognitoUser.refreshSession(state.session.getRefreshToken(), (err, session) => {
            if (err) {
              reject(err);
            } else {
              AWS.config.region = REGION
              AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                IdentityPoolId: IDENTITY_POOL_ID,
                Logins: {
                  // Change the key below according to the specific region your user pool is in.
                  [`cognito-idp.${REGION}.amazonaws.com/${USER_POOL_ID}`]: session.getIdToken().getJwtToken(),
                },
              });

              commit('setSession', session)
              resolve(session)
            }
          })
        } catch (err) {
          reject(err)
        }
      }))
      await state.sessionPromise
    },
    async getValidIdToken({ state, commit, dispatch }) {
      if (state.getValidIdTokenPromise) {
        return state.getValidIdTokenPromise
      }

      await dispatch('checkSession')
      if (state.session) {
        return state.session.getIdToken().getJwtToken()
      }
      // checkSession しても session==null
      //  ということは、UI入力からloginが必要
      //  なので、setSessionによってresolveするpromiseを作って返す
      let resolve
      const getValidIdTokenPromise = new Promise((res) => { resolve = res })
      commit('setWaitingValidIdToken', { getValidIdTokenPromise, resolve })
      return getValidIdTokenPromise
    },
    async logout({ state, commit, dispatch }) {
      if (state.session) {
        var cognitoUser = new AmazonCognitoIdentity.CognitoUser({
          Username: state.session.idToken.payload.email,
          Pool: userPool,
        });
        cognitoUser.signOut();
        commit('setSession', null)
      }

    },

    async login({ commit, dispatch }, { email, password }) {
      await dispatch('_loginCommon', { email, password })
    },
    async loginWithNewPassword({ commit, dispatch }, { email, password, newPassword }) {
      await dispatch('_loginCommon', { email, password, newPassword })
    },
    _loginCommon({ state, commit }, { email, password, newPassword }) {
      return new Promise((resolve, reject) => {
        try {
          var cognitoUser = new AmazonCognitoIdentity.CognitoUser({
            Username: email,
            Pool: userPool,
          });
          console.log("try auth");
          const callback = {
            onSuccess: function (session) {
              try {
                console.log('result', session)
                commit('setSession', session)
                resolve()
              } catch (err) {
                reject(err)
              }
            },
            newPasswordRequired: (userAttributes, requiredAttributes) => {
              try {
                console.log('newPasswordRequired.userAttributes', userAttributes)
                console.log('newPasswordRequired.requiredAttributes', requiredAttributes)
                // User was signed up by an admin and must provide new
                // password and required attributes, if any, to complete
                // authentication.

                if (newPassword) {
                  cognitoUser.completeNewPasswordChallenge(
                    newPassword,
                    {}, //  パラメータなし。
                    //  email を含んだオブジェクトを指定すると NotAuthorizedException "Cannot modify an already provided email" で失敗するため。
                    callback
                  );
                } else {
                  commit('setRequireNewPassword', true)
                  resolve()
                }
              } catch (err) {
                reject(err)
              }
            },
            onFailure: function (err) {
              console.error(err.message || JSON.stringify(err));
              reject(err)
            },
          };
          cognitoUser.authenticateUser(new AmazonCognitoIdentity.AuthenticationDetails({
            Username: email,
            Password: password,
          }), callback);
        }
        catch (err) {
          reject(err)
        }
      })
    },

    async get({ dispatch }, { url, config }) {

      config = config ? config : {}
      config = {
        baseURL: BASE_URL,
        headers: {
          Authorization: await dispatch('getValidIdToken'), //state.session.getIdToken().getJwtToken(),
          ...(config.headers ? config.headers : {}),
        },
        ...config,
      }
      try {
        return await axios.get(url, config)
      }
      catch (error) {
        const operationName = `GET:${url}`
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          const data = error.response?.data ?? {}
          if ('logGroupName' in data && 'logStreamName' in data && 'awsRequestId' in data) {
            const appendData = {
              ...('stack' in data ? { serverStack: data.stack } : {}),
              ...('logGroupName' in data ? { logGroupName: data.logGroupName } : {}),
              ...('logStreamName' in data ? { logStreamName: data.logStreamName } : {}),
              ...('awsRequestId' in data ? { awsRequestId: data.awsRequestId } : {}),
            }
            if ('code' in data && 'message' in data) {
              throw new ApiError(data.code, data.message, appendData)
            } else {
              throw new ApiError(null /*code*/, `${operationName}\n${error.response.status} ${error.response.statusText}`, appendData)
            }
          } else {
            throw new Error(`${operationName}\n${error.response.status} ${error.response.statusText}\ndata:${JSON.stringify(error.response.data, null, 2)}`)
          }
        } else {
          // Something happened in setting up the request that triggered an Error
          throw new Error(`${operationName}\n${error.message}`)
        }
      }
    },

    async post({ dispatch }, { url, data, config }) {
      console.log('{ url, data, config }', { url, data, config })
      config = config ? config : {}
      config = {
        baseURL: BASE_URL,
        headers: {
          Authorization: await dispatch('getValidIdToken'), //state.session.getIdToken().getJwtToken(),
          ...(config.headers ? config.headers : {}),
        },
        ...config,
      }
      try {
        return await axios.post(url, data, config)
      }
      catch (error) {
        const operationName = `POST:${url}`
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          const data = error.response?.data ?? {}
          if ('logGroupName' in data && 'logStreamName' in data && 'awsRequestId' in data) {
            const appendData = {
              ...('stack' in data ? { serverStack: data.stack } : {}),
              ...('logGroupName' in data ? { logGroupName: data.logGroupName } : {}),
              ...('logStreamName' in data ? { logStreamName: data.logStreamName } : {}),
              ...('awsRequestId' in data ? { awsRequestId: data.awsRequestId } : {}),
            }
            if ('code' in data && 'message' in data) {
              throw new ApiError(data.code, data.message, appendData)
            } else {
              throw new ApiError(null /*code*/, `${operationName}\n${error.response.status} ${error.response.statusText}`, appendData)
            }
          } else {
            throw new Error(`${operationName}\n${error.response.status} ${error.response.statusText}\ndata:${JSON.stringify(error.response.data, null, 2)}`)
          }
        } else {
          // Something happened in setting up the request that triggered an Error
          throw new Error(`${operationName}\n${error.message}`)
        }
      }
    },


    async delete({ dispatch }, { url, config }) {
      config = config ? config : {}
      config = {
        baseURL: BASE_URL,
        headers: {
          Authorization: await dispatch('getValidIdToken'), //state.session.getIdToken().getJwtToken(),
          ...(config.headers ? config.headers : {}),
        },
        ...config,
      }
      try {
        return await axios.delete(url, config)
      }
      catch (error) {
        const operationName = `DELETE:${url}`
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          const data = error.response?.data ?? {}
          if ('logGroupName' in data && 'logStreamName' in data && 'awsRequestId' in data) {
            const appendData = {
              ...('stack' in data ? { serverStack: data.stack } : {}),
              ...('logGroupName' in data ? { logGroupName: data.logGroupName } : {}),
              ...('logStreamName' in data ? { logStreamName: data.logStreamName } : {}),
              ...('awsRequestId' in data ? { awsRequestId: data.awsRequestId } : {}),
            }
            if ('code' in data && 'message' in data) {
              throw new ApiError(data.code, data.message, appendData)
            } else {
              throw new ApiError(null /*code*/, `${operationName}\n${error.response.status} ${error.response.statusText}`, appendData)
            }
          } else {
            throw new Error(`${operationName}\n${error.response.status} ${error.response.statusText}\ndata:${JSON.stringify(error.response.data, null, 2)}`)
          }
        } else {
          // Something happened in setting up the request that triggered an Error
          throw new Error(`${operationName}\n${error.message}`)
        }
      }
    },

  }
}
