<template>
  <span class="border px-1">{{ dispStr }}</span>
</template>
<script>
export default {
  name: "MarkerId",
  props: ["markerId"],
  data() {
    return {};
  },
  computed: {
    dispStr() {
      const match = String(this.markerId).match(/^arUco4x4_(?<id>\d+)$/)
      return match ? parseInt(match.groups.id) : this.markerId
    }
  },

  async mounted() { },

  methods: {
  },
  watch: {},
};
</script>
