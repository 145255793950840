<template>
  <div class="home">
    <Header title="バーチャル黒板 トップ" />
    <b-container class="mt-2">
      <div class="mb-4">
        <b-button :to="{ name: 'FloorFigureList' }">平面図リスト</b-button>
      </div>
      <div class="mb-4">
        <b-button :to="{ name: 'DeviceList' }">デバイスリスト</b-button>
      </div>
      <div class="mb-4">
        <b-button :to="{ name: 'MeasurementList' }">立体計測リスト</b-button>
      </div>

    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header";
import Head from "@/mixins/Head";

export default {
  name: "Top",
  components: { Header },
  mixins: [Head],
  head: {
    ...Head.head,
    title: {
      ...Head.head.title,
      inner: "トップ",
    },
  },
  data() {
    return {
      result: null
    };
  },
  computed: {},
  methods: {
  },
};
</script>
