import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/plugins/bootstrap'
import '@/plugins/bootstrap-vue'

Vue.config.productionTip = false

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
Vue.component('font-awesome-icon', FontAwesomeIcon)

import VueHead from 'vue-head'
Vue.use(VueHead)

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import { ValidationObserver, ValidationProvider, extend, localize, validate } from "vee-validate";
import { required, confirmed, length, email, regex } from "vee-validate/dist/rules";
import ja from 'vee-validate/dist/locale/ja.json';

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

localize('ja', ja)

extend("required", {
  ...required,
  // message: "This field is required"
});

extend("email", {
  ...email,
  // message: "This field must be a valid email"
});

extend("confirmed", {
  ...confirmed,
  // message: "This field confirmation does not match"
});

extend("length", {
  ...length,
  // message: "This field must have 2 options"
});
extend("regex", {
  ...regex,
  message: "{_field_}のフォーマットが正しくありません"
});

new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate() {
    this.$store.dispatch("device/loadFromLocalstorage")
  }
}).$mount('#app')
