const set = property => (state, payload) => (state[property] = payload)
const get = property => (state) => (state[property])

export default {
  namespaced: true,
  state: {
    appError: null,
  },
  getters: {
    appError: get('appError'),
  },
  mutations: {
    setAppError: set('appError'),
  },
  actions: {
    updateAppError({ commit }, appError) {
      commit('setAppError', appError)
    },
  },
}
