<template>
  <div class="home">
    <Header :title="`計測`" />
    <b-container class="mt-2">

      <div>
        <span class="mr-3">X:{{ plainBound?.minX | num2 }} ～ {{ plainBound?.maxX | num2 }}</span>
        <span class="mr-3">Y:{{ plainBound?.minY | num2 }} ～ {{ plainBound?.maxY | num2 }}</span>
        <span class="mr-3">Z:{{ plainBound?.minZ | num2 }} ～ {{ plainBound?.maxZ | num2 }}</span>
      </div>
      <DisplaySizeMeasure @resize="onResizeFloorFigureMeasure($event)"></DisplaySizeMeasure>
      <div :style="{ width: `${imageBoardWidth}px`, height: `${imageBoardHeight}px` }">
        <svg class="px-0 mx-0 border" :style="{ width: `${imageBoardWidth}px`, height: `${imageBoardHeight}px` }"
          :viewPort="`0 0 ${imageBoardWidth} ${imageBoardHeight}`"
          :viewBox="`0 0 ${imageBoardWidth} ${imageBoardHeight}`">

          <template v-for="(cam, index) in cameraRecords">
            <g :key="`c_${index}`">
              <circle :cx="cam.pix.x" :cy="cam.pix.y" r="2" :fill="`hsl(${cam.t * 300}, 100%, 50%)`" />
              <line :x1="cam.pix.x" :y1="cam.pix.y" :x2="cam.pixN.x" :y2="cam.pixN.y"
                :stroke="`hsl(${cam.t * 300}, 100%, 50%)`" />
            </g>
          </template>
          <polyline :points="cameraRecords.map(cam => `${cam.pix.x} ${cam.pix.y}`).join(',')" stroke="gray"
            stroke-width="0.5" fill="none" />


          <template v-for="(mark, markIndex) in markerRecords">
            <template v-for="(r, index) in mark.records">
              <g :key="`m_${markIndex}_${index}`">
                <circle :cx="r.pix.x" :cy="r.pix.y" r="8" :fill="`hsl(${markIndex * 50}, 50%, 50%)`"
                  :stroke="`hsl(${180 - 60 * markIndex}, 80%, 30%)`" stroke-width="2" />
                <line :x1="r.pix.x" :y1="r.pix.y" :x2="r.pixN.x" :y2="r.pixN.y" stroke="black" />
              </g>
            </template>
            <polyline :key="`m_${markIndex}`" :points="mark.records.map(r => `${r.pix.x} ${r.pix.y}`).join(',')"
              :stroke="`hsl(${markIndex * 50}, 50%, 50%)`" stroke-width="0.5" fill="none" />
          </template>

          <template v-for="(pln, planeIndex) in planeRecords">
            <template v-for="(r, index) in pln.records">
              <g :key="`m_${planeIndex}_${index}`">
                <!-- <circle :cx="r.pix.x" :cy="r.pix.y" r="2" :fill="`hsl(${planeIndex * 50}, 50%, 50%)`"
                  :stroke="`hsl(${180 - 60 * planeIndex}, 80%, 30%)`" stroke-width="2" /> -->
                <line :x1="r.pix1.x" :y1="r.pix1.y" :x2="r.pix2.x" :y2="r.pix2.y" stroke="darkgreen" />
              </g>
            </template>
            <!-- <polyline :key="`m_${planeIndex}`" :points="pln.records.map(r => `${r.pix.x} ${r.pix.y}`).join(',')"
              :stroke="`hsl(${planeIndex * 50}, 50%, 50%)`" stroke-width="0.5" fill="none" /> -->
          </template>

        </svg>

        <div class="mt-3 py-2 d-flex">
          <div class="ml-0">
            <b-button class="mr-5" @click="onClickRemove">削除</b-button>
            <b-button class="ml-auto" @click="onClickStartMake">マーカー計量に変換</b-button>
          </div>
        </div>

      </div>

    </b-container>
    <b-modal v-model="displayModalMakeNewPhysicAssoc" title="マーカー計量作成" @shown="onShownModalMakeNewPhysicAssoc"
      @ok="onOkModalMakeNewPhysicAssoc">

      <b-form-radio-group v-model="modalMakeNewPhysicAssocFloorId" :options="floorOptions" stacked></b-form-radio-group>
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import numeral from "numeral"
import Header from "@/components/Header";
import Head from "@/mixins/Head";
// import FloorFigureCanvas from "@/components/FloorFigureCanvas";
import DisplaySizeMeasure from "@/components/DisplaySizeMeasure";

export default {
  name: "Measurement",
  components: { Header, DisplaySizeMeasure },
  mixins: [Head],
  head: {
    ...Head.head,
    title: {
      ...Head.head.title,
      inner: "計測",
    },
  },
  props: ["measurementId"],
  data() {
    return {
      imageBoardTop: null, imageBoardWidth: null,
      innerWidth: null, innerHeight: null,
      plainBound: null, timeBound: null,
      rawCameraRecords: null, rawPlaneRecords: null,

      floorOptions: [],
      displayModalMakeNewPhysicAssoc: false,
      modalMakeNewPhysicAssocFloorId: null,
    };
  },
  computed: {
    imageBoardHeight() {
      if (Number.isFinite(this.innerHeight) && Number.isFinite(this.imageBoardTop)) {
        return this.innerHeight - this.imageBoardTop - 40
      } else {
        return 0
      }
    },
    transferPixFromMt() {
      if (!(
        Number.isFinite(this.plainBound?.minX) && Number.isFinite(this.plainBound?.maxX)
        && Number.isFinite(this.plainBound?.minY) && Number.isFinite(this.plainBound?.maxY)
        && Number.isFinite(this.imageBoardWidth) && Number.isFinite(this.imageBoardHeight))) {
        return null
      }

      const minX = this.plainBound.minX - (this.plainBound.maxX - this.plainBound.minX) * 0.05
      const maxX = this.plainBound.maxX + (this.plainBound.maxX - this.plainBound.minX) * 0.05
      const minY = this.plainBound.minY - (this.plainBound.maxY - this.plainBound.minY) * 0.05
      const maxY = this.plainBound.maxY + (this.plainBound.maxY - this.plainBound.minY) * 0.05
      const scale = Math.min(this.imageBoardWidth / (maxX - minX), this.imageBoardHeight / (maxY - minY))

      return {
        pixXPerMtX: -scale, pixXPerMtY: 0.0, pixX: maxX * scale,
        pixYPerMtX: 0.0, pixYPerMtY: scale, pixY: -minY * scale,
      }
    },
    transferTFromTime() {
      if (!(
        Number.isFinite(this.timeBound?.min) && Number.isFinite(this.timeBound?.max))) {
        return null
      }
      const scale = 1.0 / (this.timeBound.max - this.timeBound.min)
      return {
        scale: scale,
        c: -scale * this.timeBound.min
      }
    },
    cameraRecords() {
      if (!(this.transferPixFromMt && this.transferTFromTime && Array.isArray(this.rawCameraRecords))) { return [] }

      const pixFromMt = (() => {
        const { pixXPerMtX, pixXPerMtY, pixX, pixYPerMtX, pixYPerMtY, pixY } = this.transferPixFromMt
        return (mt) => ({ x: pixXPerMtX * mt.x + pixXPerMtY * mt.y + pixX, y: pixYPerMtX * mt.x + pixYPerMtY * mt.y + pixY, })
      })()
      const tFromTime = (() => {
        const { scale, c } = this.transferTFromTime
        return (time) => (time * scale + c)
      })()

      return this.rawCameraRecords.map(r => ({
        t: tFromTime(r.time),
        pix: pixFromMt(r.pos),
        pixN: pixFromMt({ x: r.pos.x + r.normal.x * 0.2, y: r.pos.y + r.normal.y * 0.2, }),
      }))
    },
    markerRecords() {
      if (!(this.transferPixFromMt && Array.isArray(this.rawMarkerRecords))) { return [] }

      const pixFromMt = (() => {
        const { pixXPerMtX, pixXPerMtY, pixX, pixYPerMtX, pixYPerMtY, pixY } = this.transferPixFromMt
        return (mt) => ({ x: pixXPerMtX * mt.x + pixXPerMtY * mt.y + pixX, y: pixYPerMtX * mt.x + pixYPerMtY * mt.y + pixY, })
      })()

      return this.rawMarkerRecords.map(m => ({
        id: m.id,
        records: m.records.map(r => ({
          time: r.time,
          pix: pixFromMt(r.pos),
          pixN: pixFromMt({ x: r.pos.x + r.normal.x * 0.2, y: r.pos.y + r.normal.y * 0.2, }),
        }))
      }))
    },
    planeRecords() {
      if (!(this.transferPixFromMt && Array.isArray(this.rawPlaneRecords))) { return [] }

      const pixFromMt = (() => {
        const { pixXPerMtX, pixXPerMtY, pixX, pixYPerMtX, pixYPerMtY, pixY } = this.transferPixFromMt
        return (mt) => ({ x: pixXPerMtX * mt.x + pixXPerMtY * mt.y + pixX, y: pixYPerMtX * mt.x + pixYPerMtY * mt.y + pixY, })
      })()

      return this.rawPlaneRecords.map(m => ({
        id: m.id,
        records: m.records.map(r => {
          const norm = Math.sqrt(r.normal.x * r.normal.x + r.normal.y * r.normal.y)
          const nx = r.normal.x / norm, ny = r.normal.y / norm
          return {
            time: r.time,
            pix: pixFromMt(r.pos),
            pix1: pixFromMt({ x: r.pos.x + ny * r.height * 0.5, y: r.pos.y - nx * r.height * 0.5, }),
            pix2: pixFromMt({ x: r.pos.x - ny * r.height * 0.5, y: r.pos.y + nx * r.height * 0.5, }),
          }
        })
      }))
    }
  },
  filters: {
    num2(v) {
      if (!Number.isFinite(v)) { return null }
      return numeral(v).format('+0.00')
    }
  },
  async mounted() {
    this.$store.dispatch("uiLock/incrementLoadingCount");
    try {
      this.handleResize()
      window.addEventListener('resize', this.handleResize)

      const floorFigures = await this.$store.dispatch("zumen/getFloorFigureList", {})
      this.floorOptions = floorFigures.map(f => ({
        text: f.floorName, value: f.floorId
      }))

      const {
        measurement,
        cameraRecords, markerRecords, planeRecords
      } = await this.$store.dispatch("zumen/getMeasurement", { measurementId: this.measurementId })

      this.plainBound = measurement.plainBound
      this.timeBound = measurement.timeBound
      this.rawCameraRecords = cameraRecords
      this.rawMarkerRecords = markerRecords
      this.rawPlaneRecords = planeRecords
      console.log('measurement', measurement)
    }
    finally {
      this.$store.dispatch("uiLock/decrementLoadingCount");
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    onResizeFloorFigureMeasure(e) {
      console.log('onResizeFloorFigureMeasure', e)
      this.imageBoardTop = e.top
      this.imageBoardWidth = e.width
    },

    handleResize() {
      this.innerWidth = window.innerWidth;
      this.innerHeight = window.innerHeight;
    },


    async onClickRemove() {
      this.$store.dispatch("uiLock/incrementLoadingCount");
      try {
        await this.$store.dispatch("zumen/removeMeasurement", { measurementId: this.measurementId, })

        this.$router.go(-1);
        this.$router.replace({ name: 'MeasurementList', params: {} })
      }
      finally {
        this.$store.dispatch("uiLock/decrementLoadingCount");
      }
    },
    async onClickStartMake() {
      this.displayModalMakeNewPhysicAssoc = true

    },

    async onShownModalMakeNewPhysicAssoc() {
      this.modalMakeNewPhysicAssocFloorId = null
    },
    async onOkModalMakeNewPhysicAssoc() {
      this.$store.dispatch("uiLock/incrementLoadingCount");
      try {
        const { associationId } = await this.$store.dispatch("zumen/makePhysicsAssociationFromMeasurement", {
          floorId: this.modalMakeNewPhysicAssocFloorId,
          measurementId: this.measurementId,
        })
        this.$router.replace({ name: 'FloorPhysicsAssociationMeasureEdit', params: { floorId: this.modalMakeNewPhysicAssocFloorId, associationId } })
      }
      finally {
        this.$store.dispatch("uiLock/decrementLoadingCount");
      }

    }

  },
};
</script>
