<template>
  <g :class="{ [kind]: true, hover: isHover }">
    <template v-if="kind == 'kokubanCircle'">
      <circle cx="0" cy="0" r="8" fill="none" stroke="white" stroke-width="16" />
      <circle cx="0" cy="0" r="8" fill="none" stroke="white" stroke-width="10" class="back" />
      <circle cx="0" cy="0" r="8" fill="none" stroke="#20A020" stroke-width="5" />
    </template>
    <template v-else-if="kind == 'kokubanBoard'">
      <line x1="-5" y1="-20" x2="-5" y2="20" stroke="white" stroke-width="16" stroke-linecap="round" />
      <circle cx="0" cy="0" r="5" fill="none" stroke="white" stroke-width="16" />

      <line x1="-5" y1="-20" x2="-5" y2="20" stroke="white" stroke-width="10" stroke-linecap="round" class="back" />
      <circle cx="0" cy="0" r="5" fill="none" stroke="white" stroke-width="10" class="back" />

      <line x1=" -5" y1="-20" x2="-5" y2="20" stroke="#20A020" stroke-width="5" />
      <circle cx="0" cy="0" r="5" fill="none" stroke="#20A020" stroke-width="5" />
    </template>

    <template v-if="kind == 'photoCircle'">
      <circle cx="0" cy="0" r="8" fill="none" stroke="white" stroke-width="16" />
      <circle cx="0" cy="0" r="8" fill="none" stroke="white" stroke-width="10" class="back" />
      <circle cx="0" cy="0" r="8" fill="none" stroke="#B04040" stroke-width="5" />
    </template>
    <template v-else-if="kind == 'photoBoard'">
      <line x1="-5" y1="-20" x2="-5" y2="20" stroke="white" stroke-width="16" stroke-linecap="round" />
      <circle cx="0" cy="0" r="5" fill="none" stroke="white" stroke-width="16" />

      <line x1="-5" y1="-20" x2="-5" y2="20" stroke="white" stroke-width="10" stroke-linecap="round" class="back" />
      <circle cx="0" cy="0" r="5" fill="none" stroke="white" stroke-width="10" class="back" />

      <line x1=" -5" y1="-20" x2="-5" y2="20" stroke="#B04040" stroke-width="5" />
      <circle cx="0" cy="0" r="5" fill="none" stroke="#B04040" stroke-width="5" />
    </template>

    <template v-else-if="kind == 'markerBoard'">
      <line x1="-5" y1="-20" x2="-5" y2="20" stroke="white" stroke-width="6" stroke-linecap="round" />
      <circle cx="0" cy="0" r="5" fill="none" stroke="white" stroke-width="6" />

      <line x1=" -5" y1="-20" x2="-5" y2="20" stroke="darkorange" stroke-width="3" />
      <circle cx="0" cy="0" r="5" fill="none" stroke="darkorange" stroke-width="3" />
    </template>

  </g>
</template>
<script>
export default {
  name: "MarkerId",
  props: ["kind", "isHover"],
  data() {
    return {};
  },

  async mounted() { },

  methods: {
  },
  watch: {},
};
</script>
<style scoped lang="scss">
g.kokubanCircle:hover .back {
  stroke: rgb(0, 255, 179)
}

g.kokubanCircle.hover .back {
  stroke: rgb(0, 255, 179)
}

g.kokubanBoard:hover .back {
  stroke: rgb(0, 255, 179)
}

g.kokubanBoard.hover .back {
  stroke: rgb(0, 255, 179)
}

g.photoCircle:hover .back {
  stroke: rgb(255, 101, 135)
}

g.photoCircle.hover .back {
  stroke: rgb(255, 101, 135)
}

g.photoBoard:hover .back {
  stroke: rgb(255, 101, 135)
}

g.photoBoard.hover .back {
  stroke: rgb(255, 101, 135)
}
</style>
