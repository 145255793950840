<template>
  <div class="home">
    <Header title="平面図リスト" />
    <b-container class="mt-2">
      <b-table-simple hover striped bordered responsive>
        <b-thead head-variant="dark">
          <b-tr>
            <b-th></b-th>
            <b-th>平面図名</b-th>
            <b-th>マーカー構成</b-th>
            <b-th>黒板枚数</b-th>
            <b-th>写真枚数</b-th>
            <b-th></b-th>
          </b-tr>
        </b-thead>
        <tbody>
          <template v-for="fl in floorFigures">
            <b-tr :key="`floor_${fl.floorId}`">
              <b-td>
                {{ fl.createdAt | timeYmdTms }}
              </b-td>
              <b-td>
                {{ fl.floorName }}
              </b-td>
              <b-td>
                <template v-if="fl.markerIds == null">
                  <b-spinner small></b-spinner>
                </template>
                <template v-else-if="fl.markerIds.length == 0">
                  (マーカー未定義)
                </template>
                <template v-else>
                  <template v-for="(markerId, index) of fl.markerIds">
                    <MarkerId :key="index" :markerId="markerId"></MarkerId>
                  </template>
                </template>
              </b-td>
              <b-td>
                <template v-if="!Number.isFinite(fl.kokubanCount)">
                  <b-spinner small></b-spinner>
                </template>
                <template v-else>
                  {{ fl.kokubanCount }}
                </template>
              </b-td>
              <b-td>
                <template v-if="!Number.isFinite(fl.photoCount)">
                  <b-spinner small></b-spinner>
                </template>
                <template v-else>
                  {{ fl.photoCount }}
                </template>
              </b-td>
              <b-td>
                <b-button class="py-1 mr-3" :to="{ name: 'FloorFigure', params: { floorId: fl.floorId } }"
                  variant="light" size="sm">共通設定</b-button>
                <b-button class="py-1" @click="onClickGoFloorFigure(fl.floorId)">デモ用表示</b-button>
              </b-td>
            </b-tr>
          </template>
        </tbody>
      </b-table-simple>

      <b-button variant="light" :to="{ name: 'FloorFigureCreate' }">平面図追加</b-button>

    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import { format } from 'date-fns'
import Header from "@/components/Header";
import Head from "@/mixins/Head";
import MarkerId from "@/components/MarkerId";

export default {
  name: "FloorFigureList",
  components: { Header, MarkerId },
  mixins: [Head],
  head: {
    ...Head.head,
    title: {
      ...Head.head.title,
      inner: "平面図リスト",
    },
  },
  async mounted() {
    this.$store.dispatch("uiLock/incrementLoadingCount");
    try {
      console.log('this.deviceId', this.deviceId)

      const floorFigures = await this.$store.dispatch("zumen/getFloorFigureList", {})
      this.rawFloorFigureList = floorFigures.map(f => ({
        ...f,
        markerIds: f.currentAssociationId ? null : [],
        kokubanCount: null,
        photoCount: null,
      }))
      for (const ffig of floorFigures) {
        (async () => {
          try {
            if (ffig.currentAssociationId) {
              const physicAssoc = await this.$store.dispatch("zumen/getPhysicsAssociation", { floorId: ffig.floorId, associationId: ffig.currentAssociationId })
              const markerIds = physicAssoc.markers.map(m => m.markerId)
              this.rawFloorFigureList = this.rawFloorFigureList.map(f => {
                if (f.floorId != ffig.floorId) { return f }
                return {
                  ...f,
                  markerIds
                }
              })
            }
            {
              const kokubans = await this.$store.dispatch("zumen/getKokubanList", { floorId: ffig.floorId })
              const kokubanCount = kokubans.length
              this.rawFloorFigureList = this.rawFloorFigureList.map(f => {
                if (f.floorId != ffig.floorId) { return f }
                return {
                  ...f,
                  kokubanCount
                }
              })
            }
            {
              const photos = await this.$store.dispatch("zumen/getPhotoList", { floorId: ffig.floorId })
              const photoCount = photos.length
              this.rawFloorFigureList = this.rawFloorFigureList.map(f => {
                if (f.floorId != ffig.floorId) { return f }
                return {
                  ...f,
                  photoCount
                }
              })
            }
          }
          catch (err) {
            this.$store.dispatch("app/updateAppError", { error: err, });
          }


        })()
      }
    }
    finally {
      this.$store.dispatch("uiLock/decrementLoadingCount");
    }

  },
  data() {
    return {
      rawFloorFigureList: null
    };
  },
  computed: {
    floorFigures() {
      if (Array.isArray(this.rawFloorFigureList)) {
        return this.rawFloorFigureList.map(i => ({
          floorId: i.floorId,
          createdAt: i.createdAt,
          floorName: i.floorName,
          markerIds: i.markerIds,
          kokubanCount: i.kokubanCount,
          photoCount: i.photoCount,
        })).sort((f1, f2) => -(f1.createdAt - f2.createdAt))
      } else {
        return []
      }
    }
  },
  filters: {
    timeYmdTms(unixTimeMillisec) {
      if (!Number.isFinite(unixTimeMillisec)) { return null }
      return format(new Date(unixTimeMillisec), 'yyyy-MM-dd HH:mm')
    }
  },
  methods: {
    async onClickGoFloorFigure(floorId) {

      let currentDevice
      this.$store.dispatch("uiLock/incrementLoadingCount");
      try {
        const { devices } = await this.$store.dispatch("device/getDeviceList", {})
        console.log('devices', devices)
        currentDevice = devices.find(d => d.deviceId == this.$store.getters['device/currentDeviceId'])
      }
      finally {
        this.$store.dispatch("uiLock/decrementLoadingCount");
      }

      console.log('currentDevice', currentDevice)
      if (currentDevice) {
        this.$router.push({ name: 'DeviceFloorFigure', params: { deviceId: currentDevice.deviceId, floorId: floorId } })
      } else {
        await this.$bvModal.msgBoxOk('デバイスリストでデモ機を指定してください。')
      }

    }
  },
};
</script>
