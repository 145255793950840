<template>
  <div class="layout-wrap">
    <!-- <Loading /> -->
    <div class="front-wrap">
      <router-view />
      <div v-if="isLockingUi" id="fullOverlay">
        <div class="text">
          <b-spinner></b-spinner>
          処理中です…
        </div>
      </div>
    </div>
    <AppErrorWatchDialog />
    <SessionCheckerComponent />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import AppErrorWatchDialog from "@/components/AppErrorWatchDialog";
import SessionCheckerComponent from "@/components/SessionCheckerComponent";

export default {
  name: "App",
  components: {
    AppErrorWatchDialog,
    SessionCheckerComponent,
  },
  computed: {
    ...mapGetters('uiLock', ["isLockingUi"]),

  },
  async created() {
  },
  errorCaptured(err, vm, info) {
    console.warn(err, vm, info);
    this.$store.dispatch("app/updateAppError", {
      error: err,
    });
    return true;
  },
  methods: {
  },
};
</script>
<style scoped>
.layout-wrap {
  height: 100%;
}

.front-wrap {}

#fullOverlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 2147483647;
}

#fullOverlay .text {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  color: black;
  font-size: 24px;
  text-align: center;
}
</style>
