import ExtensibleCustomError from 'extensible-custom-error';

export class ApiError extends ExtensibleCustomError {
  constructor(code, message, { serverStack, logGroupName, logStreamName, awsRequestId }) {
    super(message);

    const flags = { configurable: true, enumerable: true, writable: true }
    Object.defineProperty(this, 'code', { ...flags, value: code });
    if (serverStack) {
      Object.defineProperty(this, 'serverStack', { ...flags, value: serverStack });
    }
    if (logGroupName) {
      Object.defineProperty(this, 'logGroupName', { ...flags, value: logGroupName });
    }
    if (logStreamName) {
      Object.defineProperty(this, 'logStreamName', { ...flags, value: logStreamName });
    }
    if (awsRequestId) {
      Object.defineProperty(this, 'awsRequestId', { ...flags, value: awsRequestId });
    }
  }
}