import Vue from 'vue'
import VueRouter from 'vue-router'

import Top from '../views/Top.vue'

import FloorFigureList from '../views/FloorFigureList.vue'
import FloorFigure from '../views/FloorFigure.vue'
import FloorFigureCreate from '../views/FloorFigureCreate.vue'

import FloorPhysicsAssociationList from '../views/FloorPhysicsAssociationList.vue'
import FloorPhysicsAssociationEdit from '../views/FloorPhysicsAssociationEdit.vue'
import FloorPhysicsAssociationMeasureEdit from '../views/FloorPhysicsAssociationMeasureEdit.vue'

import KokubanList from '../views/KokubanList.vue'

import DeviceList from '../views/DeviceList.vue'

import MeasurementList from '../views/MeasurementList.vue'
import MeasurementCreate from '../views/MeasurementCreate.vue'
import Measurement from '../views/Measurement.vue'


Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'Top',
    component: Top
  },
  {
    path: '/floorFigures',
    name: 'FloorFigureList',
    component: FloorFigureList
  },
  {
    path: '/floorFigures/create',
    name: 'FloorFigureCreate',
    component: FloorFigureCreate
  },
  {
    path: '/floorFigure/:floorId/floor',
    name: 'FloorFigure',
    props: true,
    component: FloorFigure
  },

  {
    path: '/floorFigure/:floorId/physicsAssocList',
    name: 'FloorPhysicsAssociationList',
    props: true,
    component: FloorPhysicsAssociationList
  },
  {
    path: '/floorFigure/:floorId/physicsAssoc/:associationId/edit',
    name: 'FloorPhysicsAssociationEdit',
    props: true,
    component: FloorPhysicsAssociationEdit
  },

  {
    path: '/floorFigure/:floorId/physicsAssocMeasure/:associationId/edit',
    name: 'FloorPhysicsAssociationMeasureEdit',
    props: true,
    component: FloorPhysicsAssociationMeasureEdit
  },

  {
    path: '/floorFigure/:floorId/kokubans',
    name: 'KokubanList',
    props: true,
    component: KokubanList
  },


  {
    path: '/devices/:deviceId/floorFigure/:floorId/floor',
    name: 'DeviceFloorFigure',
    props: true,
    component: FloorFigure
  },
  {
    path: '/devices/:deviceId/floorFigure/:floorId/kokubans',
    name: 'DeviceKokubanList',
    props: true,
    component: KokubanList
  },

  {
    path: '/devices',
    name: 'DeviceList',
    component: DeviceList
  },


  {
    path: '/measurements',
    name: 'MeasurementList',
    component: MeasurementList
  },
  {
    path: '/measurements/create',
    name: 'MeasurementCreate',
    props: true,
    component: MeasurementCreate
  },
  {
    path: '/measurements/:measurementId/measurement',
    name: 'Measurement',
    props: true,
    component: Measurement
  },



  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
