<template>
  <div class="home">
    <Header
      :title="`平面図 [${floorName ? floorName : '...'}] - 黒板リスト ${targetDevice ? ' (' + targetDevice.deviceCode + ')' : ''}`" />
    <b-container class="mt-2">
      <b-row>
        <b-col class="pr-0" cols="5">
          <b-overlay :show="operation != null" rounded="sm"
            :style="{ height: `${floorFigureCanvasHeight}px`, overflowY: operation != null ? 'hidden' : 'auto' }">
            <b-card no-body>
              <b-tabs card active-tab-class="px-0 py-0" v-model="kokubanPhotoTabIndex">
                <b-tab title="黒板" active>
                  <div class="px-2">
                    <DisplaySizeMeasure @resize="onResizeKokubanListMeasure($event)"></DisplaySizeMeasure>
                  </div>
                  <div width="100%" class="px-2 pt-2"
                    :style="{ height: `${innerHeight - kokubanListTop - 20}px`, overflowY: 'scroll' }">
                    <div width="100%" class="border py-2 mb-2">
                      <div class="d-flex px-3">
                        <b-button class="mx-auto px-5" variant="primary" size="lg" @click="onClickStartMakeNewKokuban">
                          作成</b-button>
                      </div>
                      <template v-if="!targetDevice">
                        <b-form-checkbox v-model="isDisplayOtherDevice">別端末データも表示</b-form-checkbox>
                      </template>
                    </div>

                    <template v-for="k in kokubans">
                      <div :key="`k_${k.createdAt}`" width="100%" class="kokubanItem border pb-2 mb-2"
                        :class="{ hover: k.isHover }" @mouseover="onMouseoverKokuban(k.createdAt)"
                        @mouseleave="onMouseleaveKokuban(k.createdAt)">
                        <template v-if="!k.visible">
                          (別端末上でのみ表示)
                        </template>
                        <div
                          :style="{ width: `${kokubanBitmapWidth}px`, height: `${kokubanBitmapWidth * k.bitmapSizePx.height / k.bitmapSizePx.width}px` }">
                          <template v-if="k.isProcessing">
                            <div class="d-flex px-3">
                              <div class="mt-5 mx-auto">
                                <b-spinner></b-spinner>
                              </div>
                            </div>
                          </template>
                          <template v-else-if="k.url">
                            <img :src="k.url" :width="kokubanBitmapWidth" />
                          </template>
                          <template v-else>
                            <div class="d-flex px-3">
                              <b-button class="mt-5 mx-auto" variant="primary" size="lg"
                                @click="onClickStartKokubanInput(k.createdAt)">
                                黒板入力</b-button>
                            </div>
                          </template>
                        </div>
                        <div class="d-flex mt-1 px-3">
                          <template v-if="k.visible">
                            <b-button class="ml-auto" @click="onClickRemoveKokuban(k.createdAt)">削除</b-button>
                          </template>
                        </div>
                      </div>
                    </template>
                  </div>
                </b-tab>
                <b-tab title="写真">

                  <div class="px-2">
                    <DisplaySizeMeasure @resize="onResizeKokubanListMeasure($event)"></DisplaySizeMeasure>
                  </div>
                  <div width="100%" class="px-2 pt-2"
                    :style="{ height: `${innerHeight - kokubanListTop - 20}px`, overflowY: 'scroll' }">
                    <div width="100%" class="border py-2 mb-2">
                      <div class="d-flex px-3">
                        <b-button class="mx-auto px-5" variant="primary" size="lg" @click="onClickStartMakeNewPhoto">
                          作成</b-button>
                      </div>
                      <template v-if="!targetDevice">
                        <b-form-checkbox v-model="isDisplayOtherDevice">別端末データも表示</b-form-checkbox>
                      </template>
                    </div>

                    <template v-for="p in photos">
                      <div :key="`p_${p.createdAt}`" width="100%" class="photoItem border pb-2 mb-2"
                        :class="{ hover: p.isHover }" @mouseover="onMouseoverPhoto(p.createdAt)"
                        @mouseleave="onMouseleavePhoto(p.createdAt)">
                        <template v-if="!p.visible">
                          (別端末上でのみ表示)
                        </template>
                        <div
                          :style="{ width: `${photoBitmapWidth}px`, height: `${photoBitmapWidth * p.bitmapSizePx.height / p.bitmapSizePx.width}px` }">
                          <img :src="p.url" :width="kokubanBitmapWidth" />
                        </div>
                        <div class="d-flex mt-1 px-3">
                          <template v-if="p.visible">
                            <b-button class="ml-auto" @click="onClickRemovePhoto(p.createdAt)">削除</b-button>
                          </template>
                          <template v-else-if="p.photoId">
                            <b-button class="" @click="onClickPhotoCopyCommon(p.photoId)">共通設定にコピー</b-button>
                          </template>
                        </div>
                      </div>
                    </template>

                  </div>
                </b-tab>
              </b-tabs>
            </b-card>
            <template #overlay>
              <div class="text-center">

                <div class="mb-3">図面をクリックしてください</div>

                <b-button ref="cancel" variant="outline-primary" size="sm" aria-describedby="cancel-label"
                  @click="onClickInputCancel">
                  キャンセル
                </b-button>
              </div>
            </template>
          </b-overlay>
        </b-col>
        <b-col cols="7" class="px-0">
          <DisplaySizeMeasure @resize="onResizeFloorFigureMeasure($event)"></DisplaySizeMeasure>
          <FloorFigureCanvas :floorId="floorId" :width="floorFigureCanvasWidth" :height="floorFigureCanvasHeight"
            :transfer="canvasTransfer" @click="onClickFloorFigure($event)" @mousemove="onMousemoveFloorFigure($event)">

            <template v-slot:default="p">

              <template v-for="m in figureMarks">
                <g :key="m.key">
                  <template v-if="m.kokuban">
                    <template v-if="'pos2' in m.kokuban.location">
                      <g class="kokubanMark"
                        :transform="(m.kokuban.definition.meter ? p.transformFromMeter : p.transformFromPix)(m.kokuban.location.pos2.pos, { x: 1, y: 0 })"
                        @mouseover="onMouseoverKokuban(m.kokuban.createdAt)"
                        @mouseleave="onMouseleaveKokuban(m.kokuban.createdAt)">
                        <SvgMark kind="kokubanCircle" :isHover="m.kokuban.isHover"></SvgMark>
                      </g>
                    </template>
                    <template v-else-if="'pos3Normal' in m.kokuban.location">
                      <g class="kokubanMark"
                        :transform="(m.kokuban.definition.meter ? p.transformFromMeter : p.transformFromPix)(m.kokuban.location.pos3Normal.pos, m.kokuban.location.pos3Normal.normal)"
                        @mouseover="onMouseoverKokuban(m.kokuban.createdAt)"
                        @mouseleave="onMouseleaveKokuban(m.kokuban.createdAt)">
                        <SvgMark kind="kokubanBoard" :isHover="m.kokuban.isHover"></SvgMark>
                      </g>
                    </template>
                  </template>
                  <template v-else-if="m.photo">
                    <template v-if="'pos2' in m.photo.location">
                      <g class="photoMark"
                        :transform="(m.photo.definition.meter ? p.transformFromMeter : p.transformFromPix)(m.photo.location.pos2.pos, { x: 1, y: 0 })"
                        @mouseover="onMouseoverPhoto(m.photo.createdAt)"
                        @mouseleave="onMouseleavePhoto(m.photo.createdAt)">
                        <SvgMark kind="photoCircle" :isHover="m.photo.isHover"></SvgMark>
                      </g>
                    </template>
                    <template v-else-if="'pos3Normal' in m.photo.location">
                      <g class="photoMark"
                        :transform="(m.photo.definition.meter ? p.transformFromMeter : p.transformFromPix)(m.photo.location.pos3Normal.pos, m.photo.location.pos3Normal.normal)"
                        @mouseover="onMouseoverPhoto(m.photo.createdAt)"
                        @mouseleave="onMouseleavePhoto(m.photo.createdAt)">
                        <SvgMark kind="photoBoard" :isHover="m.photo.isHover"></SvgMark>
                      </g>
                    </template>
                  </template>
                </g>
              </template>
            </template>

          </FloorFigureCanvas>
          <div style="position:absolute; top:0px; left:0px; background-color: white; opacity: 80%;">
            <div>Pixel:({{ mousePixelX | num1 }}, {{ mousePixelY | num1 }})</div>
            <div>Meter:({{ mouseMeterX | numS2 }}, {{ mouseMeterY | numS2 }})</div>
          </div>

        </b-col>

      </b-row>



    </b-container>

    <b-modal v-model="displayModalKokubanInput" title="黒板入力" @shown="onShownModalKokubanInput"
      @ok="onOkModalKokubanInput">


      <b-form-textarea ref="modalKokubanInputKokubanText" v-model="modalKokubanText" placeholder="黒板内容を入力..." rows="3"
        max-rows="6">
      </b-form-textarea>

      <div v-show="modalKokubanInputForDev">
        <b-form-group label="開発中暫定">
          <b-form-radio v-model="modalKokubanInputDevOption" :value="null">param2D (本来はこれしか入力できない)
          </b-form-radio>
          <b-form-radio v-model="modalKokubanInputDevOption" :value="{ param3D: 'front' }">(param3D
            図面中心に表を向ける)
          </b-form-radio>
          <b-form-radio v-model="modalKokubanInputDevOption" :value="{ param3D: 'back' }">(param3D
            図面中心に裏を向ける)
          </b-form-radio>
          <b-form-radio v-model="modalKokubanInputDevOption" :value="{ param3D: { normal: { x: 1, y: 0 } } }">(param3D
            normal(1,0))
          </b-form-radio>
          <b-form-radio v-model="modalKokubanInputDevOption" :value="{ param3D: { normal: { x: -1, y: 0 } } }">
            (param3D
            normal(-1,0)) </b-form-radio>
          <b-form-radio v-model="modalKokubanInputDevOption" :value="{ param3D: { normal: { x: 0, y: 1 } } }">(param3D
            normal(0,1))
          </b-form-radio>
          <b-form-radio v-model="modalKokubanInputDevOption" :value="{ param3D: { normal: { x: 0, y: -1 } } }">
            (param3D
            normal(0,-1)) </b-form-radio>
        </b-form-group>
      </div>

      <div>
        <b-form-checkbox v-model="modalKokubanInputForDev" tabindex="-1">開発用</b-form-checkbox>
      </div>
    </b-modal>
    <b-modal v-model="displayModalPhotoUpload" title="写真アップロード" @ok="onOkModalPhotoUpload">
      <b-form-file v-model="modalPhotoUploadFile" accept=".png,.jpg,.jpeg" browse-text="選択"
        placeholder="写真画像ファイルを 選択 または ドラッグ&ドロップ..." drop-placeholder="写真画像ファイルをドロップ..."></b-form-file>

      <div v-if="modalPhotoUploadFilePreviewUrl != null">
        <img :src="modalPhotoUploadFilePreviewUrl" width="100" />
      </div>


    </b-modal>

  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header";
import Head from "@/mixins/Head";
import FloorFigureCanvas from "@/components/FloorFigureCanvas";
import DisplaySizeMeasure from "@/components/DisplaySizeMeasure";
import SvgMark from "@/components/SvgMark";
import numeral from "numeral"


export default {
  name: "KokubanList",
  components: { Header, FloorFigureCanvas, DisplaySizeMeasure, SvgMark },
  mixins: [Head],
  head: {
    ...Head.head,
    title: {
      ...Head.head.title,
      inner: "黒板リスト",
    },
  },
  props: ["floorId", "deviceId"],
  data() {
    return {
      targetDevice: null,
      innerWidth: null, innerHeight: null,
      floorFigureCanvasTop: null, floorFigureCanvasWidth: null,

      kokubanListTop: null, kokubanListWidth: null,
      photoListTop: null, photoListWidth: null,

      floorName: null,
      floorFigureSizePx: null,
      associationId: null,

      mousePixelX: null, mousePixelY: null,
      mouseMeterX: null, mouseMeterY: null,

      kokubanPhotoTabIndex: 0,

      operation: null,

      canvasTransfer: null,
      rawKokubans: [],

      displayModalKokubanInput: false,
      modalKokubanOperation: null,
      modalKokubanText: null,
      modalKokubanInputForDev: false,
      modalKokubanInputDevOption: null,

      rawPhotos: [],
      displayModalPhotoUpload: false,
      modalPhotoUploadOperation: null,
      modalPhotoUploadFile: null,
      modalPhotoUploadFilePreviewUrl: null,

      isDisplayOtherDevice: false,
    };
  },
  computed: {
    floorFigureCanvasHeight() {
      if (Number.isFinite(this.innerHeight) && Number.isFinite(this.floorFigureCanvasTop) /*&& Number.isFinite(this.footerHeight)*/) {
        return this.innerHeight - this.floorFigureCanvasTop - 5
      } else {
        return 0
      }
    },
    kokubanBitmapWidth() {
      return this.kokubanListWidth - 30
    },
    kokubans() {
      return this.rawKokubans
        .filter(k => (k.visible || this.isDisplayOtherDevice))
        .slice().sort((k1, k2) => -(k1.createdAt - k2.createdAt))
    },

    photoBitmapWidth() {
      return this.photoListWidth - 30
    },
    photos() {
      return this.rawPhotos
        .filter(p => (p.visible || this.isDisplayOtherDevice))
        .slice().sort((k1, k2) => -(k1.createdAt - k2.createdAt))
    },

    figureMarks() {
      const kokubans = this.kokubans.map(k => ({ key: `kmark_${k.createdAt}`, kokuban: k }))
      const photos = this.photos.map(p => ({ key: `pmark_${p.createdAt}`, photo: p }))
      if (this.kokubanPhotoTabIndex == 0) {
        //  タブは黒板表示中。なので黒板列が手前になるように後に描画
        return photos.concat(kokubans)
      } else {
        //  タブは写真表示中。なので黒板列が手前になるように後に描画
        return kokubans.concat(photos)
      }
    }

  },
  async mounted() {
    this.$store.dispatch("uiLock/incrementLoadingCount");
    try {
      this.handleResize()
      window.addEventListener('resize', this.handleResize)

      this.targetDevice = null
      if (this.deviceId) {
        const { devices } = await this.$store.dispatch("device/getDeviceList", {})
        this.targetDevice = devices.find(d => d.deviceId == this.deviceId)
      }

      const floorFigure = await this.$store.dispatch("zumen/getFloorFigure", { floorId: this.floorId })
      this.floorName = floorFigure.floorName
      this.floorFigureSizePx = { width: floorFigure.figureSizePx.width, height: floorFigure.figureSizePx.height }

      const physicAssoc = await this.$store.dispatch("zumen/getPhysicsAssociation", { floorId: this.floorId, associationId: floorFigure.currentAssociationId })
      this.associationId = physicAssoc.associationId
      this.canvasTransfer = ('pxFromMt' in physicAssoc.transfer) ? { pxFromMt: physicAssoc.transfer.pxFromMt } : { mtFromPx: physicAssoc.transfer.mtFromPx }

      await Promise.all([this.loadKokubanList(), this.loadPhotoList()])
    }
    finally {
      this.$store.dispatch("uiLock/decrementLoadingCount");
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  filters: {
    num1(v) {
      if (!Number.isFinite(v)) { return null }
      return numeral(v).format('0.0')
    },
    numS2(v) {
      if (!Number.isFinite(v)) { return null }
      return numeral(v).format('+0.00')
    }
  },
  methods: {
    async loadKokubanList() {
      const kokubans = await this.$store.dispatch("zumen/getKokubanList", { floorId: this.floorId, deviceId: this.targetDevice ? this.targetDevice.deviceId : null })
      if (!Array.isArray(this.rawKokubans)) { this.rawKokubans = [] }
      this.rawKokubans = kokubans.map(k => ({
        createdAt: k.createdAt,
        kokubanId: k.kokubanId,
        bitmapSizePx: { width: k.bitmapSizePx.width, height: k.bitmapSizePx.height, },
        location: k.location,
        definition: k.definition,
        isProcessing: false,
        url: k.kokubanBitmapUrl,
        visible: k.visible,
        isHover: false
      }))
    },
    async loadPhotoList() {
      const photos = await this.$store.dispatch("zumen/getPhotoList", { floorId: this.floorId, deviceId: this.targetDevice ? this.targetDevice.deviceId : null })
      if (!Array.isArray(this.rawPhotos)) { this.rawPhotos = [] }
      this.rawPhotos = photos.map(k => ({
        createdAt: k.createdAt,
        photoId: k.photoId,
        bitmapSizePx: { width: k.bitmapSizePx.width, height: k.bitmapSizePx.height, },
        location: k.location,
        definition: k.definition,
        isProcessing: false,
        url: k.photoBitmapUrl,
        visible: k.visible,
        isHover: false
      }))

    },
    onResizeFloorFigureMeasure(e) {
      this.floorFigureCanvasTop = e.top
      this.floorFigureCanvasWidth = e.width
    },
    onResizeKokubanListMeasure(e) {
      this.kokubanListTop = e.top
      this.kokubanListWidth = e.width
    },
    onResizePhotoListMeasure(e) {
      this.photoListTop = e.top
      this.photoListWidth = e.width
    },

    handleResize() {
      this.innerWidth = window.innerWidth;
      this.innerHeight = window.innerHeight;
    },

    onMousemoveFloorFigure(e) {
      const { imagePixelX, imagePixelY, meterX, meterY } = e
      this.mousePixelX = imagePixelX
      this.mousePixelY = imagePixelY
      this.mouseMeterX = meterX
      this.mouseMeterY = meterY
    },

    onClickFloorFigure(e) {
      const { imagePixelX, imagePixelY, meterX, meterY } = e
      if (this.operation != null && typeof (this.operation.onClick) == 'function') {
        this.operation.onClick({ imagePixelX, imagePixelY, meterX, meterY })
      }
    },
    onMouseoverKokuban(createdAt) {
      console.log('mouseOver', createdAt)
      this.rawKokubans = this.rawKokubans.map(k => {
        if (k.createdAt != createdAt) { return k }
        return {
          ...k,
          isHover: true
        }
      })
    },
    onMouseleaveKokuban(createdAt) {
      console.log('mouseLeave', createdAt)
      this.rawKokubans = this.rawKokubans.map(k => {
        if (k.createdAt != createdAt) { return k }
        return {
          ...k,
          isHover: false
        }
      })
    },

    onClickInputCancel() {
      this.operation = null
    },

    onShownModalKokubanInput() {
      this.$refs.modalKokubanInputKokubanText.focus()
    },

    onClickStartMakeNewKokuban() {
      this.operation = {
        onClick: ({ imagePixelX, imagePixelY, meterX, meterY }) => {
          const createdAt = Date.now()
          this.rawKokubans = [{
            kokubanId: null,
            createdAt: createdAt,
            bitmapSizePx: {
              width: 330, height: 220,
            },
            location: {
              pos2: {
                pos: { x: imagePixelX, y: imagePixelY }
              }
            },
            definition: {
              pixel: {}
            },
            isProcessing: false,
            url: null,
            isHover: false,

            editorInfo: {
              meterX, meterY
            },
            visible: true
          }].concat(this.rawKokubans)
          this.onClickInputCancel()

          this.onClickStartKokubanInput(createdAt)
        }
      }
    },

    onClickStartKokubanInput(kokubanCreatedAt) {
      this.modalKokubanOperation = {
        onOk: () => {
          this.rawKokubans = this.rawKokubans.map(k => {
            if (k.createdAt != kokubanCreatedAt) { return k }
            return {
              ...k,
              isProcessing: true
            }
          })
          const kokuban = this.rawKokubans.find(k => k.createdAt == kokubanCreatedAt)
          console.log('onOk', kokuban)
          if (kokuban) {
            (async () => {
              try {
                const { location, definition } = (() => {
                  if (this.modalKokubanInputDevOption && this.modalKokubanInputDevOption.param3D && kokuban.editorInfo) {
                    if (typeof (this.modalKokubanInputDevOption.param3D) == 'string') {
                      const pixX = kokuban.location.pos2.pos.x
                      const pixY = kokuban.location.pos2.pos.y
                      const pixCenterX = (this.floorFigureSizePx.width * 0.5)
                      const pixCenterY = (this.floorFigureSizePx.height * 0.5)
                      return {
                        location: {
                          pos3Normal: {
                            pos: { x: pixX, y: pixY, z: 1.5 },
                            normal: (this.modalKokubanInputDevOption.param3D == 'front') ? { x: pixCenterX - pixX, y: pixCenterY - pixY } : { x: -(pixCenterX - pixX), y: -(pixCenterY - pixY) }
                          }
                        },
                        definition: { pixel: {} }
                      }

                    } else {
                      return {
                        location: {
                          pos3Normal: {
                            pos: { x: kokuban.editorInfo.meterX, y: kokuban.editorInfo.meterY, z: 1.5 },
                            normal: { x: this.modalKokubanInputDevOption.param3D.normal.x, y: this.modalKokubanInputDevOption.param3D.normal.y },
                          },
                        },
                        definition: { meter: { associationId: this.associationId } }
                      }
                    }
                  }
                  return {
                    location: {
                      pos2: { pos: { x: kokuban.location.pos2.pos.x, y: kokuban.location.pos2.pos.y } }
                    },
                    definition: { pixel: {} }
                  }
                })()

                const { kokubanId, bitmapSizePx, kokubanBitmapUrl } = await this.$store.dispatch("zumen/makeKokuban", {
                  floorId: this.floorId,
                  location: location,
                  definition: definition,
                  kokubanText: this.modalKokubanText ?? '',
                  deviceId: this.targetDevice ? this.targetDevice.deviceId : null
                })
                this.rawKokubans = this.rawKokubans.map(k => {
                  if (k.createdAt != kokuban.createdAt) { return k }
                  return {
                    ...k,
                    kokubanId: kokubanId,
                    bitmapSizePx,
                    location, definition,
                    isProcessing: false,
                    url: kokubanBitmapUrl,
                    visible: true,
                  }
                })
              }
              catch (err) {
                this.$store.dispatch("app/updateAppError", { error: err, });
              }
            })()
          }
        }
      }
      this.modalKokubanInputForDev = false
      this.modalKokubanInputDevOption = null
      this.modalKokubanText = null
      this.displayModalKokubanInput = true
    },
    async onOkModalKokubanInput() {
      if (this.modalKokubanOperation && this.modalKokubanOperation.onOk) {
        this.modalKokubanOperation.onOk()
      }
    },


    async onClickRemoveKokuban(createdAt) {
      const kokuban = this.rawKokubans.find(k => k.createdAt == createdAt)
      if (!kokuban) { return }
      this.$store.dispatch("uiLock/incrementLoadingCount");
      try {
        if (kokuban.kokubanId) {
          await this.$store.dispatch("zumen/removeKokuban", {
            floorId: this.floorId,
            kokubanId: kokuban.kokubanId,
            deviceId: this.targetDevice ? this.targetDevice.deviceId : null
          })
        }
        this.rawKokubans = this.rawKokubans.filter(k => k.createdAt != createdAt)
      }
      finally {
        this.$store.dispatch("uiLock/decrementLoadingCount");
      }
    },


    onClickStartMakeNewPhoto() {
      this.operation = {
        onClick: async ({ imagePixelX, imagePixelY, meterX, meterY }) => {
          const createdAt = Date.now()
          const location = {
            pos3Normal: {
              pos: { x: imagePixelX, y: imagePixelY, z: 1.0 },
              normal: { x: (this.floorFigureSizePx.width * 0.5) - imagePixelX, y: (this.floorFigureSizePx.height * 0.5) - imagePixelY }
            }
          }
          const definition = { pixel: {} }

          let uploadPhotoBitmapToken = null

          this.$store.dispatch("uiLock/incrementLoadingCount");
          try {
            this.rawPhotos = [{
              photoId: null,
              createdAt: createdAt,
              bitmapSizePx: {
                width: 330, height: 220,
              },
              location, definition,
              url: null,
              isHover: false,

              editorInfo: {
                meterX, meterY
              }
            }].concat(this.rawPhotos)
            this.onClickInputCancel()

            const { photoId, bitmapSizePx, photoBitmapUrl, uploadPhotoBitmapToken: uploadPhotoBitmapToken_ } = await this.$store.dispatch("zumen/makePhoto", {
              floorId: this.floorId,
              location, definition,
              deviceId: this.targetDevice ? this.targetDevice.deviceId : null
            })
            uploadPhotoBitmapToken = uploadPhotoBitmapToken_
            this.rawPhotos = this.rawPhotos.map(p => {
              if (p.createdAt != createdAt) { return p }
              return {
                ...p,
                photoId: photoId,
                bitmapSizePx,
                location, definition,
                url: photoBitmapUrl,
              }
            })
          }
          finally {
            this.$store.dispatch("uiLock/decrementLoadingCount");
          }

          this.modalPhotoUploadOperation = {
            onOk: async () => {
              this.$store.dispatch("uiLock/incrementLoadingCount");
              try {
                await this.$store.dispatch("zumen/uploadPhotoBitmap", {
                  uploadPhotoBitmapToken,
                  bitmapFile: this.modalPhotoUploadFile,
                })
                this.loadPhotoList()
              }
              finally {
                this.$store.dispatch("uiLock/decrementLoadingCount");
              }

            }
          }
          this.modalPhotoUploadFile = null
          this.modalPhotoUploadFilePreviewUrl = null
          this.displayModalPhotoUpload = true
        }
      }
    },

    async onOkModalPhotoUpload() {
      if (this.modalPhotoUploadOperation && this.modalPhotoUploadOperation.onOk) {
        await this.modalPhotoUploadOperation.onOk()
      }
    },

    onMouseoverPhoto(createdAt) {
      console.log('mouseOver', createdAt)
      this.rawPhotos = this.rawPhotos.map(k => {
        if (k.createdAt != createdAt) { return k }
        return {
          ...k,
          isHover: true
        }
      })
    },
    onMouseleavePhoto(createdAt) {
      console.log('mouseLeave', createdAt)
      this.rawPhotos = this.rawPhotos.map(k => {
        if (k.createdAt != createdAt) { return k }
        return {
          ...k,
          isHover: false
        }
      })
    },
    async onClickRemovePhoto(createdAt) {
      const photo = this.rawPhotos.find(k => k.createdAt == createdAt)
      if (!photo) { return }
      this.$store.dispatch("uiLock/incrementLoadingCount");
      try {
        if (photo.photoId) {
          await this.$store.dispatch("zumen/removePhoto", {
            floorId: this.floorId,
            photoId: photo.photoId,
            deviceId: this.targetDevice ? this.targetDevice.deviceId : null
          })
        }
        this.rawPhotos = this.rawPhotos.filter(k => k.createdAt != createdAt)
      }
      finally {
        this.$store.dispatch("uiLock/decrementLoadingCount");
      }
    },

    async onClickPhotoCopyCommon(photoId) {
      this.$store.dispatch("uiLock/incrementLoadingCount");
      try {
        await this.$store.dispatch("zumen/copyCommonPhoto", {
          floorId: this.floorId,
          photoId: photoId,
        })
        await this.loadPhotoList()
      }
      finally {
        this.$store.dispatch("uiLock/decrementLoadingCount");
      }
    },

  },
  watch: {
    modalPhotoUploadFile() {
      if (this.modalPhotoUploadFile) {
        this.modalPhotoUploadFilePreviewUrl = URL.createObjectURL(this.modalPhotoUploadFile)
      } else {
        this.modalPhotoUploadFilePreviewUrl = null
      }
    }
  }
};
</script>
<style scoped lang="scss">
div.kokubanItem.hover {
  outline: rgb(0, 255, 179) 5px solid
}

div.photoItem.hover {
  outline: rgb(255, 101, 135) 5px solid
}
</style>
