import axios from "axios";
import { uniq, groupBy } from "lodash";

const toDevice = (i) => {
  return {
    ...i
  }
}

export default {
  namespaced: true,
  state: {
    currentDeviceId: null,
  },
  getters: {
    currentDeviceId(state) {
      return state.currentDeviceId
    }
  },
  mutations: {
    setCurrentDeviceId(state, payload) {
      const { deviceId } = payload

      state.currentDeviceId = deviceId
    }
  },
  actions: {
    async getDeviceList({ dispatch, commit, state }) {
      const { data } = await dispatch("api/get", {
        url: "devices",
        config: { params: {} },
      }, { root: true });
      console.log('get devices', JSON.stringify(data, null, 2))
      return {
        devices: data.devices.map(d => toDevice(d.device)),
        commonDevice: data.commonDevice
      }
    },
    async modifyDevice({ dispatch, commit, state }, payload) {
      const { deviceId, appSettingYaml } = payload

      const { data } = await dispatch("api/post", {
        url: `devices/${deviceId}/device`,
        data: {
          appSettingYaml
        },
      }, { root: true });
      console.log('modify device', JSON.stringify(data, null, 2))
    },

    async setCurrentDeviceId({ dispatch, commit, state }, payload) {
      const { deviceId } = payload

      localStorage.setItem('device', JSON.stringify({ currentDeviceId: deviceId }))

      commit('setCurrentDeviceId', { deviceId })
    },

    async loadFromLocalstorage({ dispatch, commit, state }) {
      try {
        const content = JSON.parse(localStorage.getItem('device'))
        commit('setCurrentDeviceId', { deviceId: content.currentDeviceId ? content.currentDeviceId : null })
      } catch (error) {
        console.warn(error)
      }
    }

  },
}
