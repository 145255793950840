import axios from "axios";
import { uniq, groupBy } from "lodash";

const UPLOADTEMP_FLOORNAME_PREFIX = "#uploadTemp#"
const toFloorFigure = (i) => {
  return {
    floorId: i.floorId,
    floorName: i.floorName,
    createdAt: i.createdAt,
    figureSizePx: { width: i.figureSizePx.width, height: i.figureSizePx.height },
    currentAssociationId: i.currentAssociationId,
  }
}
const toPhysicsAssociation = (i) => {
  return {
    ...i
    // floorId: i.floorId,
    // floorName: i.floorName,
    // figureSizePx: { width: i.figureSizePx.width, height: i.figureSizePx.height },
  }
}

const toMeasurement = (i) => {
  return {
    ...i
    // floorId: i.floorId,
    // floorName: i.floorName,
    // figureSizePx: { width: i.figureSizePx.width, height: i.figureSizePx.height },
  }
}
const toKokuban = (kokuban, kokubanBitmapUrl, visible) => {
  return {
    ...kokuban,
    kokubanBitmapUrl,
    visible
  }
}
const toPhoto = (photo, photoBitmapUrl, visible) => {
  return {
    ...photo,
    photoBitmapUrl: photoBitmapUrl,
    visible
  }
}

export default {
  namespaced: true,
  state: {
    floorFigures: null
  },
  getters: {
  },
  mutations: {
    setFloorFigure(state, payload) {
      const { floorId, floorFigure, floorBitmapUrl } = payload
      if (!Array.isArray(state.floorFigures)) {
        state.floorFigures = []
      }
      if (state.floorFigures.some(i => i.floorId == floorId)) {
        state.floorFigures = state.floorFigures.map(i => {
          if (i.floorId != floorId) { return i }
          return {
            ...i,
            ...(floorFigure ? { floorFigure } : {}),
            ...(floorBitmapUrl ? { floorBitmapUrl } : {}),
          }
        })
      } else {
        state.floorFigures.push({
          floorId,
          ...(floorFigure ? { floorFigure } : {}),
          ...(floorBitmapUrl ? { floorBitmapUrl } : {}),
        })
      }
    },
    clearInvalidFloorFigure(state, payload) {
      const { validFloorIds } = payload
      if (Array.isArray(state.floorFigures)) {
        state.floorFigures = state.floorFigures.filter(f => validFloorIds.includes(f.floorId))
      }
    },
    clearFloorFigureCache(state) {
      state.floorFigures = null
    }
  },
  actions: {
    async getFloorFigureList({ dispatch, commit, state }) {
      const { data } = await dispatch("api/get", {
        url: "floorFigures",
        config: { params: {} },
      }, { root: true });
      const floorIds = []
      for (const item of data.floorFigures) {
        const floorFigure = toFloorFigure(item.floorFigure)
        if (!floorFigure.floorName.startsWith(UPLOADTEMP_FLOORNAME_PREFIX)) {
          commit('setFloorFigure', { floorId: item.floorFigure.floorId, floorFigure: toFloorFigure(item.floorFigure), floorBitmapUrl: item.floorBitmapUrl })
          floorIds.push(item.floorFigure.floorId)
        }
      }
      commit('clearInvalidFloorFigure', { validFloorIds: floorIds })

      return Array.isArray(state.floorFigures) ? state.floorFigures.map(item => item.floorFigure) : []
    },
    async getFloorFigure({ dispatch, commit, state }, payload) {
      const { floorId } = payload

      if (!Array.isArray(state.floorFigures)) {
        await dispatch('getFloorFigureList')
      }
      const item = state.floorFigures.find(i => i.floorId == floorId)
      if (!item) {
        throw new Error(`floorId[${floorId}] not found.`)
      }
      return item.floorFigure
    },
    async getFloorFigureBitmapUrl({ dispatch, commit, state }, payload) {
      const { floorId, forceReload } = { forceReload: false, ...payload }
      if (!Array.isArray(state.floorFigures)) {
        await dispatch('getFloorFigureList')
      }
      const item = state.floorFigures.find(i => i.floorId == floorId)
      if (!item) {
        throw new Error(`floorId[${floorId}] not found.`)
      }
      return item.floorBitmapUrl
    },
    async makeFloorFigure({ dispatch, commit, state }, payload) {
      const { floorName, floorBitmapFile } = { ...payload }

      //  ビットマップアップロード用のURLを取得
      const { uploadUrl, uploadTempId } = await (async () => {
        const { data } = await dispatch("api/post", {
          url: `floorFigures`,
          data: {},
        }, { root: true });
        // console.log('POST floorFigures . response', data)
        const { uploadUrl, uploadTempId } = data
        return { uploadUrl, uploadTempId }
      })()

      //  アップロード
      await axios.put(uploadUrl, floorBitmapFile)

      //  アップロードをトリガーにして登録が完了すると、特殊なfloorNameのデータとして作成されるはず。待機してfloorIdを特定する
      const floorId = await (async () => {
        const sleepSec = 3
        for (let retry = 0; retry < 5; retry++) {
          await new Promise(resolve => setTimeout(resolve, sleepSec * 1000))

          const { data } = await dispatch("api/get", {
            url: "floorFigures",
            config: { params: {} },
          }, { root: true });
          // console.log('makeFloorFigure GET floorFigures response', JSON.stringify(data, null, 2))
          for (const item of data.floorFigures) {
            const floorFigure = toFloorFigure(item.floorFigure)
            if (floorFigure.floorName.startsWith(UPLOADTEMP_FLOORNAME_PREFIX) && floorFigure.floorName.substring(UPLOADTEMP_FLOORNAME_PREFIX.length) == uploadTempId) {
              return item.floorFigure.floorId
            }
          }
        }
        return null
      })()
      // console.log('floorId', floorId)
      if (floorId == null) {
        throw new Error('平面図アップロード失敗。')
      }

      //  入力された他のデータを反映させる
      const { data } = await dispatch("api/post", {
        url: `floorFigures/${floorId}/floorFigure`,
        data: {
          floorName: floorName
        },
      }, { root: true });
      commit('setFloorFigure', { floorId: data.floorFigure.floorId, floorFigure: toFloorFigure(data.floorFigure), floorBitmapUrl: data.floorBitmapUrl })

      return {
        floorId
      }
    },
    async removeFloorFigure({ dispatch, commit, state }, payload) {
      const { floorId } = payload
      const { data } = await dispatch("api/delete", {
        url: `floorFigures/${floorId}/floorFigure`,
        config: { params: {} },
      }, { root: true });
      // console.log('removeFloorFigure.response', JSON.stringify(data, null, 2))

      commit('clearFloorFigureCache')
    },
    async getPhysicsAssociationList({ dispatch, commit, state }, payload) {
      const { floorId } = payload
      const { data } = await dispatch("api/get", {
        url: `floorFigures/${floorId}/physicsAssociations`,
        config: { params: {} },
      }, { root: true });
      // console.log('getPhysicsAssociationList.response', JSON.stringify(data, null, 2))
      return data.floorPhysicsAssociations.map(i => toPhysicsAssociation(i.floorPhysicsAssociation))
    },

    async getPhysicsAssociation({ dispatch, commit, state }, payload) {
      const { floorId, associationId } = payload
      const { data } = await dispatch("api/get", {
        url: `floorFigures/${floorId}/physicsAssociations/${associationId}/physicAssoc`,
        config: { params: {} },
      }, { root: true });
      // console.log('getPhysicsAssociation.response', JSON.stringify(data, null, 2))
      return toPhysicsAssociation(data.floorPhysicsAssociation)
    },

    async makeOrModifyPhysicsAssociation({ dispatch, commit, state }, payload) {
      const {
        floorId, associationId,
        origin, axisX, axisY,
        markers, invalidMarkers,
      } = payload
      const problems = []
      if (invalidMarkers.length > 0) {
        problems.push(`マーカーエラー[${uniq(invalidMarkers.flatMap(m => m?.errors ?? [])).join(',')}]`)
      } else if (markers.length == 0) {
        problems.push('マーカー定義なし')
      }
      if (associationId == null) {
        const { data } = await dispatch("api/post", {
          url: `floorFigures/${floorId}/physicsAssociations`,
          data: {
            editor: {
              manual: {
                origin, axisX, axisY,
              }
            },
            markers, invalidMarkers,
            problems
          },
        }, { root: true });
      } else {
        const { data } = await dispatch("api/post", {
          url: `floorFigures/${floorId}/physicsAssociations/${associationId}/physicAssoc`,
          data: {
            editor: {
              manual: {
                origin, axisX, axisY,
              }
            },
            markers, invalidMarkers,
            problems
          },
        }, { root: true });
      }

      commit('clearFloorFigureCache')
    },

    async modifyPhysicsAssociationMeasurement({ dispatch, commit, state }, payload) {
      const {
        floorId, associationId,
        pixelMeterPair, transferPxFromMt,
        markers, invalidMarkers,
      } = payload
      const problems = []
      if (invalidMarkers.length > 0) {
        problems.push(`マーカーエラー[${uniq(invalidMarkers.flatMap(m => m?.errors ?? [])).join(',')}]`)
      } else if (markers.length == 0) {
        problems.push('マーカー定義なし')
      }
      const { data } = await dispatch("api/post", {
        url: `floorFigures/${floorId}/physicsAssociations/${associationId}/physicAssoc`,
        data: {
          editor: {
            measurement: {
              pixelMeterPair, transferPxFromMt
            }
          },
          markers, invalidMarkers,
          problems
        },
      }, { root: true });

      commit('clearFloorFigureCache')
    },

    async setPhysicsAssociationCurrent({ dispatch, commit, state }, payload) {
      const { floorId, associationId, } = { ...payload }
      const { data } = await dispatch("api/post", {
        url: `floorFigures/${floorId}/floorFigure`,
        data: {
          currentAssociationId: associationId
        },
      }, { root: true });

      commit('clearFloorFigureCache')
    },


    async removePhysicsAssociation({ dispatch, commit, state }, payload) {
      const { floorId, associationId } = payload
      const { data } = await dispatch("api/delete", {
        url: `floorFigures/${floorId}/physicsAssociations/${associationId}/physicAssoc`,
        config: { params: {} },
      }, { root: true });
      // console.log('removeFloorFigure.response', JSON.stringify(data, null, 2))

      commit('clearFloorFigureCache')
    },

    async getKokubanList({ dispatch, commit, state }, payload) {
      const { floorId, deviceId } = payload
      const { data } = await dispatch("api/get", {
        url: `floorFigures/${floorId}/kokubans`,
        config: { params: { deviceId } },
      }, { root: true });
      // console.log('getPhysicsAssociationList.response', JSON.stringify(data, null, 2))
      return data.kokubans.map(i => (toKokuban(i.kokuban, i.kokubanBitmapUrl, i.visible)))
    },

    async makeKokuban({ dispatch, commit, state }, payload) {
      const { floorId, location, definition, kokubanText, deviceId } = payload
      const { data } = await dispatch("api/post", {
        url: `floorFigures/${floorId}/kokubans`,
        data: {
          location, definition, kokubanText,
          deviceId
        },
      }, { root: true });
      console.log('post Kokubans.response', JSON.stringify(data, null, 2))
      return {
        kokubanId: data.kokuban.kokubanId,
        bitmapSizePx: { width: data.kokuban.bitmapSizePx.width, height: data.kokuban.bitmapSizePx.height },
        kokubanBitmapUrl: data.kokubanBitmapUrl,
      }
    },

    async removeKokuban({ dispatch, commit, state }, payload) {
      const { floorId, kokubanId, deviceId } = payload
      const { data } = await dispatch("api/delete", {
        url: `floorFigures/${floorId}/kokubans/${kokubanId}/kokuban`,
        config: { params: { deviceId } },
      }, { root: true });
    },


    async getPhotoList({ dispatch, commit, state }, payload) {
      const { floorId, deviceId } = payload
      const { data } = await dispatch("api/get", {
        url: `floorFigures/${floorId}/photos`,
        config: { params: { deviceId } },
      }, { root: true });
      // console.log('getPhysicsAssociationList.response', JSON.stringify(data, null, 2))
      return data.photos.map(i => (toPhoto(i.photo, i.photoBitmapUrl, i.visible)))
    },

    async makePhoto({ dispatch, commit, state }, payload) {
      const { floorId, location, definition, deviceId } = payload
      const { data } = await dispatch("api/post", {
        url: `floorFigures/${floorId}/photos`,
        data: {
          location, definition,
          deviceId
        },
      }, { root: true });
      console.log('post Photos.response', JSON.stringify(data, null, 2))
      return {
        photoId: data.photo.photoId,
        bitmapSizePx: { width: data.photo.bitmapSizePx.width, height: data.photo.bitmapSizePx.height },
        photoBitmapUrl: data.photoBitmapUrl,
        uploadPhotoBitmapToken: {
          floorId,
          photoId: data.photo.photoId,
          putPhotoBitmapReplaceUrl: data.putPhotoBitmapReplaceUrl,
          deviceId
        }
      }
    },
    async uploadPhotoBitmap({ dispatch, commit, state }, payload) {
      const { uploadPhotoBitmapToken: { floorId, photoId, putPhotoBitmapReplaceUrl, deviceId }, bitmapFile } = payload

      //  アップロード
      await axios.put(putPhotoBitmapReplaceUrl, bitmapFile)

      //  アップロードをトリガーにして登録が完了すると、仮photoが削除されるはず
      const removedOldPhoto = await (async () => {
        const sleepSec = 3
        for (let retry = 0; retry < 5; retry++) {
          await new Promise(resolve => setTimeout(resolve, sleepSec * 1000))

          const { data } = await dispatch("api/get", {
            url: `floorFigures/${floorId}/photos/${photoId}/photo`,
            config: { params: { deviceId, allowNonExists: 'true' } },
          }, { root: true });
          console.log('uploadPhotoBitmap photo.response', JSON.stringify(data, null, 2))
          if (!('photo' in data)) {
            return true //  該当写真が存在しないので、待機完了
          }
        }
        return false
      })()
      // console.log('floorId', floorId)
      if (!removedOldPhoto) {
        throw new Error('写真アップロード失敗。')
      }
    },


    async removePhoto({ dispatch, commit, state }, payload) {
      const { floorId, photoId, deviceId } = payload
      const { data } = await dispatch("api/delete", {
        url: `floorFigures/${floorId}/photos/${photoId}/photo`,
        config: { params: { deviceId } },
      }, { root: true });
    },

    async resetKokubanPhotoForDemo({ dispatch, commit, state }, payload) {
      const { floorId, deviceId } = payload
      const { data } = await dispatch("api/post", {
        url: `floorFigures/${floorId}/resetForDemo`,
        data: {
          deviceId
        },
      }, { root: true });
    },

    async copyCommonPhoto({ dispatch, commit, state }, payload) {
      const { floorId, photoId } = payload
      const { data } = await dispatch("api/post", {
        url: `floorFigures/${floorId}/photos/${photoId}/photo`,
        data: {
          deviceId: null,
          visible: true
        },
      }, { root: true });
    },


    async makePhysicsAssociationFromMeasurement({ dispatch, commit, state }, payload) {
      const { floorId, measurementId } = payload

      const { data } = await dispatch("api/post", {
        url: `measurements/${measurementId}/measurement`,
        data: {
          floorId: floorId
        },
      }, { root: true });
      console.log('makePhysicsAssociationFromMeasurement.resposnse', data)

      return {
        associationId: data.associationId
      }

    },

    async getMeasurementList({ dispatch, commit, state }, payload) {
      const { data } = await dispatch("api/get", {
        url: `measurements`,
        config: { params: {} },
      }, { root: true });
      // console.log('getMeasurementList.response', JSON.stringify(data, null, 2))
      return data.measurements.map(i => toMeasurement(i.measurement))
    },

    async getMeasurement({ dispatch, commit, state }, payload) {
      // console.log('getMeasurement.payload', payload)
      const { measurementId } = { ...payload }
      const { data } = await dispatch("api/get", {
        url: `measurements/${measurementId}/measurement`,
        config: { params: { withRecordList: "true" } },
      }, { root: true });
      // console.log('getMeasurement.response', JSON.stringify(data, null, 2))

      const cameraRecords = data.records.filter(r => 'camera' in r).map(r => ({ time: r.time, pos: r.camera.pos, normal: r.camera.normal }))
      // console.log('cameraRecords', JSON.stringify(cameraRecords, null, 2))

      const markerRecords = Array.from(Object.values(
        groupBy(
          data.records.filter(r => 'markers' in r).flatMap(r => r.markers.map(m => ({ time: r.time, marker: m }))).map(i => ({ id: i.marker.id, time: i.time, pos: i.marker.pos, normal: i.marker.normal })),
          (i) => i.id
        )
      )).map(vals => (
        {
          id: vals[0].id,
          records: vals.map(v => ({ time: v.time, pos: v.pos, normal: v.normal }))
        }
      ))
      // console.log('markerRecords', JSON.stringify(markerRecords, null, 2))

      const planeRecords = Array.from(Object.values(
        groupBy(
          data.records.filter(r => 'planes' in r).flatMap(r => r.planes.map(m => ({ time: r.time, plane: m }))).map(i => ({ id: i.plane.id, time: i.time, pos: i.plane.pos, normal: i.plane.normal, width: i.plane.width, height: i.plane.height })),
          (i) => i.id
        )
      )).map(vals => (
        {
          id: vals[0].id,
          records: vals.map(v => ({ time: v.time, pos: v.pos, normal: v.normal, width: v.width, height: v.height }))
        }
      ))
      // console.log('planeRecords', JSON.stringify(planeRecords, null, 2))


      return {
        measurement: toMeasurement(data.measurement),
        cameraRecords,
        markerRecords,
        planeRecords,
      }
    },
    async removeMeasurement({ dispatch, commit, state }, payload) {
      const { measurementId } = { ...payload }
      const { data } = await dispatch("api/delete", {
        url: `measurements/${measurementId}/measurement`,
        config: { params: {} },
      }, { root: true });
    },
    async makeMeasurement({ dispatch, commit, state }, payload) {
      const { cameraJsonFile } = { ...payload }

      //  jsonアップロード用のURLを取得
      const { uploadUrl, measurementId } = await (async () => {
        const { data } = await dispatch("api/post", {
          url: `measurements`,
          data: {},
        }, { root: true });
        // console.log('POST floorFigures . response', data)
        const { uploadUrl, measurementId } = data
        return { uploadUrl, measurementId }
      })()

      //  アップロード
      await axios.put(uploadUrl, cameraJsonFile)

      //  アップロードをトリガーにして登録が完了すると、発行されたmeasurementIdがリストに追加されるはず。待機
      const uploadedMeasurementId = await (async () => {
        const sleepSec = 3
        for (let retry = 0; retry < 5; retry++) {
          await new Promise(resolve => setTimeout(resolve, sleepSec * 1000))

          const { data } = await dispatch("api/get", {
            url: `measurements`,
            config: { params: {} },
          }, { root: true });

          for (const item of data.measurements) {
            const measurement = toMeasurement(item.measurement)
            if (measurement.measurementId == measurementId) {
              return measurementId
            }
          }
        }
        return null
      })()
      // console.log('floorId', floorId)
      if (uploadedMeasurementId == null) {
        throw new Error('計測データアップロード失敗。')
      }

      return {
        measurementId: uploadedMeasurementId
      }
    },

  },
}
