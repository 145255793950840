<template>
  <div>
    <div ref="base"></div>
    <b-modal
      id="modalAppErrorWatchDialog"
      title="エラー"
      size="lg"
      ok-only
      @close="onModalAppErrorClose"
      @hide="onModalAppErrorClose"
    >
      <template v-if="appError && appError.error">
        <div class="mb-2 d-flex">
          <b-button
            ref="myButton"
            @click="onCopyToClipBoard"
            class="mx-auto"
            size="lg"
            ><div>クリップボードにコピーする</div>
            <div class="small">(RocketChatに貼って開発に報告)</div></b-button
          >
        </div>
        <b-alert v-model="dispAlert" dismissible>
          <div>
            コピーしました。RocketChatなどに「ペースト」して開発に報告してください。
          </div>
          <div>ご迷惑おかけします。</div>
        </b-alert>
        <b-alert v-model="dispAlertError" variant="danger" dismissible>
          <div>クリップボードにコピーできませんでした。</div>
          <div>
            ↓の画面をマウスで「選択」「コピー」操作をして、RocketChatなどに「ペースト」して開発に報告してください。
          </div>
          <div>ご迷惑おかけします。</div>
        </b-alert>
        <pre ref="errorContent1">{{ currentTimestamp }}</pre>
        <pre ref="errorContent2">{{ appError.error.toString() }}</pre>
        <pre ref="errorContent3">{{
          JSON.parse(JSON.stringify(appError.error))
        }}</pre>
        <pre v-if="appError.error.stack" ref="errorContent4">{{
          JSON.parse(JSON.stringify(appError.error.stack))
        }}</pre>
        <b-link
          v-if="awsWebConsoleLogStreamUrl"
          :href="awsWebConsoleLogStreamUrl"
          target="_blank"
          >AWSコンソール LogStream へ</b-link
        >
      </template>
      <template v-else>
        <pre>{{ appError }}</pre>
      </template>
    </b-modal>
  </div>
</template>
<script>
import moment from "moment-timezone";
import { mapGetters } from "vuex";

export default {
  name: "AppErrorWatchDialog",
  data() {
    return {
      currentTimestamp: "",
      dispAlert: false,
      dispAlertError: false,
    };
  },
  computed: {
    ...mapGetters("app", ["appError"]),
    awsWebConsoleLogStreamUrl() {
      if (!(this.appError && this.appError.error)) {
        return null;
      }
      const error = this.appError.error;
      if (!("logGroupName" in error && "logStreamName" in error)) {
        return null;
      }
      const url =
        "https://ap-northeast-1.console.aws.amazon.com/cloudwatch/home?region=ap-northeast-1#logsV2:log-groups/log-group/" +
        encodeURIComponent(encodeURIComponent(error.logGroupName)) +
        "/log-events/" +
        encodeURIComponent(encodeURIComponent(error.logStreamName));
      if ("awsRequestId" in error) {
        return (
          url +
          encodeURI(encodeURI('?filterPattern="' + error.awsRequestId + '"'))
        );
      } else {
        return url;
      }
    },
  },

  async mounted() {
    this.currentTimestamp = moment().format();
  },

  methods: {
    onModalAppErrorClose() {
      console.log("onModalAppErrorClose");
      this.$router.go({
        path: this.$router.currentRoute.fullPath,
        force: true,
      });
    },
    async onCopyToClipBoard() {
      try {
        let message =
          "```\n" +
          ["errorContent1", "errorContent2", "errorContent3", "errorContent4"]
            .map((key) => (this.$refs[key] ? this.$refs[key].innerText : ""))
            .join("\n\n") +
          "\n```\n";
        if (this.awsWebConsoleLogStreamUrl != null) {
          message +=
            "AWSコンソール LogStream へ\n" +
            this.awsWebConsoleLogStreamUrl +
            "\n";
        }

        await this.$copyText(message, this.$refs.myButton);
        this.dispAlert = true;
      } catch (e) {
        console.log(e);
        this.dispAlertError = true;
      }
    },
  },
  watch: {
    appError: {
      handler(theAppError) {
        if (!theAppError) {
          // エラー初期化された場合は表示しない
          return;
        }
        // this.show(theAppError); // エラーコードを確定してダイアログ表示
        this.$bvModal.show("modalAppErrorWatchDialog");
      },
      deep: true,
    },
  },
};
</script>
