import { render, staticRenderFns } from "./KokubanList.vue?vue&type=template&id=40f0afb9&scoped=true&"
import script from "./KokubanList.vue?vue&type=script&lang=js&"
export * from "./KokubanList.vue?vue&type=script&lang=js&"
import style0 from "./KokubanList.vue?vue&type=style&index=0&id=40f0afb9&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40f0afb9",
  null
  
)

export default component.exports