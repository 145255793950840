<template>
  <div>
    <b-modal
      id="modalSessionCheckerLogin"
      title="アカウント確認"
      hide-footer
      hide-header-close
      @close="onModalSessionCheckerLoginClose"
      @hide="onModalSessionCheckerLoginClose"
    >
      <template v-if="!isRequireNewPassword">
        <!--  email,password でのログイン  -->
        <ValidationObserver
          ref="loginObserver"
          mode="eager"
          v-slot="{ handleSubmit, invalid }"
        >
          <b-form @submit.prevent="handleSubmit(onLogin)">
            <ValidationProvider
              rules="required|email"
              name="Email"
              v-slot="{ valid, errors }"
            >
              <b-form-group label="Email address:" label-for="exampleInput1">
                <b-form-input
                  type="email"
                  v-model="email"
                  :state="errors[0] ? false : valid ? true : null"
                  placeholder="Enter email"
                ></b-form-input>
                <b-form-invalid-feedback id="inputLiveFeedback">{{
                  errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider
              rules="required"
              name="Password"
              vid="password"
              v-slot="{ valid, errors }"
            >
              <b-form-group label="Password:">
                <b-form-input
                  type="password"
                  v-model="password"
                  :state="errors[0] ? false : valid ? true : null"
                  placeholder="Enter password"
                ></b-form-input>
                <b-form-invalid-feedback id="inputLiveFeedback">{{
                  errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider name="Form" v-slot="{ errors }">
              <b-button
                type="submit"
                variant="primary"
                :disabled="invalid || isProcessing"
                :class="{ 'is-invalid': true }"
              >
                <span v-if="isProcessing"><b-spinner small></b-spinner></span
                ><span v-else>ログイン</span>
              </b-button>
              <b-form-invalid-feedback id="inputLiveFeedback">{{
                errors[0]
              }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form>
        </ValidationObserver>
      </template>
      <template v-else>
        <!--  初回ログインでの、email,password,newPasswordでのログイン  -->
        <ValidationObserver
          ref="loginWithNewPasswordObserver"
          mode="eager"
          v-slot="{ handleSubmit, invalid }"
        >
          <b-form @submit.prevent="handleSubmit(onLoginWithNewPassword)">
            <ValidationProvider
              rules="required|email"
              name="Email"
              v-slot="{ valid, errors }"
            >
              <b-form-group label="Email address:" label-for="exampleInput1">
                <b-form-input
                  type="email"
                  v-model="email"
                  :state="errors[0] ? false : valid ? true : null"
                  placeholder="Enter email"
                ></b-form-input>
                <b-form-invalid-feedback id="inputLiveFeedback">{{
                  errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider
              rules="required"
              name="Password"
              vid="password"
              v-slot="{ valid, errors }"
            >
              <b-form-group label="Password:">
                <b-form-input
                  type="password"
                  v-model="password"
                  :state="errors[0] ? false : valid ? true : null"
                  placeholder="Enter password"
                ></b-form-input>
                <b-form-invalid-feedback id="inputLiveFeedback">{{
                  errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider
              rules="required"
              name="NewPassword"
              vid="newPassword"
              v-slot="{ valid, errors }"
            >
              <b-form-group
                label="New Password:"
                description="パスワード変更が必要です( 大文字,小文字,記号,数字を含む 8文字以上 )"
              >
                <b-form-input
                  type="password"
                  v-model="newPassword"
                  :state="errors[0] ? false : valid ? true : null"
                  placeholder="Enter password"
                ></b-form-input>
                <b-form-invalid-feedback id="inputLiveFeedback">{{
                  errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider
              rules="required|confirmed:newPassword"
              name="Password confirmation"
              v-slot="{ valid, errors }"
            >
              <b-form-group
                label="Confirm New Password:"
                label-for="exampleInput1"
                description="確認のためもう一度パスワードを入力してください。"
              >
                <b-form-input
                  type="password"
                  v-model="newPasswordConfirmation"
                  :state="errors[0] ? false : valid ? true : null"
                  placeholder="Confirm Password"
                ></b-form-input>
                <b-form-invalid-feedback id="inputLiveFeedback">{{
                  errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider name="Form" v-slot="{ errors }">
              <b-button
                type="submit"
                variant="primary"
                :disabled="invalid || isProcessing"
                :class="{ 'is-invalid': errors.length > 0 }"
              >
                <span v-if="isProcessing"><b-spinner small></b-spinner></span
                ><span v-else>パスワードを変更してログイン</span>
              </b-button>
              <b-form-invalid-feedback id="inputLiveFeedback">{{
                errors[0]
              }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form>
        </ValidationObserver>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "SessionCheckerComponent",
  data() {
    return {
      email: null,
      password: null,
      newPassword: null,
      newPasswordConfirmation: null,

      isProcessing: false,
    };
  },
  computed: {
    ...mapGetters("api", ["isLogout", "isRequireNewPassword"]),
  },

  async mounted() {
    await this.$store.dispatch("api/checkSession");
    if (this.isLogout) {
      // session確認が終わってもログアウト状態
      //  ということは、email,passwordでのログインが必要。
      //  なので、ログインモーダルを表示
      this.showModal();
    }
  },

  methods: {
    showModal() {
      // this.email emailは入力済み値があれば保持
      this.password = null;
      this.newPassword = null;
      this.newPasswordConfirmation = null;
      this.$bvModal.show("modalSessionCheckerLogin");
    },
    async onLogin() {
      this.isProcessing = true;
      try {
        await this.$store.dispatch("api/login", {
          email: this.email,
          password: this.password,
        });
      } catch (error) {
        console.log("SessionCheckerComponent.error", error);
        if (error.code == "NotAuthorizedException") {
          this.$refs.loginObserver.reset();
          this.$refs.loginObserver.setErrors({
            Form:
              error.message == "Password attempts exceeded"
                ? "認証失敗が多くて一時的にロックされています。しばらく待ってからやりなおしてください。"
                : "Email または パスワード が違います。",
          });
        } else if (error.code == "UserNotFoundException") {
          this.$refs.loginObserver.reset();
          this.$refs.loginObserver.setErrors({
            Email: "Email が登録されていません。",
          });
        } else {
          this.$refs.loginObserver.reset();
          this.$refs.loginObserver.setErrors({
            Form: error.message,
          });
        }
      } finally {
        this.isProcessing = false;
      }
    },
    async onLoginWithNewPassword() {
      this.isProcessing = true;
      try {
        await this.$store.dispatch("api/loginWithNewPassword", {
          email: this.email,
          password: this.password,
          newPassword: this.newPassword,
        });
      } catch (error) {
        this.$refs.loginWithNewPasswordObserver.reset();
        this.$refs.loginWithNewPasswordObserver.setErrors({
          Form: error.message,
        });
      } finally {
        this.isProcessing = false;
      }
    },
    onModalSessionCheckerLoginClose(evt) {
      if (this.isLogout) {
        evt.preventDefault();
      }
    },
  },
  watch: {
    isLogout: {
      handler(isLogout) {
        if (isLogout) {
          this.showModal();
        } else {
          this.$bvModal.hide("modalSessionCheckerLogin");
        }
      },
    },
  },
};
</script>
