<template>
  <div class="home">
    <Header :title="`平面図 [${floorName ? floorName : '...'}] - マーカー測量リスト`" />
    <b-container class="mt-2">
      <b-table-simple hover striped bordered responsive>
        <b-thead head-variant="dark">
          <b-tr>
            <b-th>計量日時</b-th>
            <b-th>代表</b-th>
            <b-th>マーカー構成</b-th>
            <b-th>問題</b-th>
            <b-th></b-th>
          </b-tr>
        </b-thead>
        <tbody>
          <template v-for="pa in physicsAssociationList">
            <b-tr :key="`${pa.associationId}`">
              <b-td>
                {{ pa.createdAt | timeYmdTms }}
              </b-td>
              <b-td>
                <template v-if="pa.isCurrent">
                  代表
                </template>
                <template v-else-if="pa.problems.length == 0">
                  <div class="d-flex">
                    <b-button class="ml-auto" size="sm" variant="light" @click="onClickSetCurrent(pa.associationId)">選択
                    </b-button>
                  </div>
                </template>
                <template v-else>
                </template>
              </b-td>
              <b-td>
                <template v-for="(markerId, index) of pa.markerIds">
                  <MarkerId :key="index" :markerId="markerId"></MarkerId>
                </template>
              </b-td>
              <b-td>
                <template v-for="(problem, index) of pa.problems">
                  <p :key="index">{{ problem }}</p>
                </template>
              </b-td>
              <b-td>
                <template v-if="pa.editorKind == 'manual'">
                  <b-button class="py-1"
                    :to="{ name: 'FloorPhysicsAssociationEdit', params: { floorId: floorId, associationId: pa.associationId } }">
                    修正</b-button>
                </template>
                <template v-else-if="pa.editorKind == 'measurement'">
                  <b-button class="py-1"
                    :to="{ name: 'FloorPhysicsAssociationMeasureEdit', params: { floorId: floorId, associationId: pa.associationId } }">
                    修正</b-button>
                </template>
              </b-td>
            </b-tr>
          </template>
        </tbody>
      </b-table-simple>

      <b-button class="mr-2" variant="light"
        :to="{ name: 'FloorPhysicsAssociationEdit', params: { floorId: floorId, associationId: '*new*' } }">測量追加(手計測)
      </b-button>
      <!-- <b-button class="mr-2" variant="light">計量追加(アプリ)</b-button>
      <b-button class="mr-2" variant="light">計量追加(立体計測)</b-button> -->

    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import { format } from 'date-fns'
import Header from "@/components/Header";
import Head from "@/mixins/Head";
import MarkerId from "@/components/MarkerId";


export default {
  name: "FloorPhysicsAssociationList",
  components: { Header, MarkerId },
  mixins: [Head],
  head: {
    ...Head.head,
    title: {
      ...Head.head.title,
      inner: "マーカー測量リスト",
    },
  },
  props: ["floorId"],
  async mounted() {
    this.$store.dispatch("uiLock/incrementLoadingCount");
    try {
      await this.loadViewData()
    }
    finally {
      this.$store.dispatch("uiLock/decrementLoadingCount");
    }
  },
  data() {
    return {
      floorName: null,
      currentAssociationId: null,
      rawPhysicsAssociationList: null,
    };
  },
  filters: {
    timeYmdTms(unixTimeMillisec) {
      if (!Number.isFinite(unixTimeMillisec)) { return null }
      return format(new Date(unixTimeMillisec), 'yyyy-MM-dd HH:mm')
    }
  },
  computed: {
    physicsAssociationList() {
      if (Array.isArray(this.rawPhysicsAssociationList)) {
        return this.rawPhysicsAssociationList.map(i => ({
          isCurrent: i.associationId == this.currentAssociationId,
          markerIds: i.markers.map(m => m.markerId),
          editorKind: (() => {
            if (i.measurementId && 'measurement' in i.editorInfo) {
              return 'measurement'
            } else {
              return 'manual'
            }
          })(),
          ...i
        }))
      } else {
        return []
      }
    }
  },
  methods: {
    async loadViewData() {
      const [floorFigure, physicsAssociationList] = await Promise.all([
        (async () => {
          const floorFigure = await this.$store.dispatch("zumen/getFloorFigure", { floorId: this.floorId })
          return floorFigure
        })(),
        (async () => {
          const physicsAssociationList = await this.$store.dispatch("zumen/getPhysicsAssociationList", { floorId: this.floorId })
          return physicsAssociationList
        })(),
      ])
      // console.log('physicsAssociationList', physicsAssociationList)
      this.floorName = floorFigure.floorName
      this.currentAssociationId = floorFigure.currentAssociationId
      this.rawPhysicsAssociationList = physicsAssociationList

    },
    async onClickSetCurrent(associationId) {
      this.$store.dispatch("uiLock/incrementLoadingCount");
      try {
        await this.$store.dispatch("zumen/setPhysicsAssociationCurrent", { floorId: this.floorId, associationId })
        await this.loadViewData()
      }
      finally {
        this.$store.dispatch("uiLock/decrementLoadingCount");
      }

    }
  },
};
</script>
