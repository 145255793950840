import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    loadingCount: 0,
  },
  getters: {
    isLockingUi(state) {
      return state.loadingCount > 0
    }
  },
  mutations: {
    incrementLoadingCount(state) {
      // console.log('incrementLoadingCount')
      state.loadingCount += 1
    },
    decrementLoadingCount(state) {
      // console.log('decrementLoadingCount')
      state.loadingCount -= 1
    },
  },
  actions: {
    incrementLoadingCount({ state, dispatch, commit, getters, rootGetters }) {
      commit('incrementLoadingCount')
    },
    decrementLoadingCount({ state, dispatch, commit, getters, rootGetters }) {
      Vue.nextTick(() => {
        // 遷移前画面がdecrementして、直後に遷移先画面でincrementしたときにチラつく問題を回避するために1フレーム待機する
        commit('decrementLoadingCount')
      })
    }
  },
}
