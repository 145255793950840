<template>
  <div class="home">
    <Header title="平面図作成" />
    <b-container class="mt-2">

      <div class="mb-2">
        <label for="input-live">平面図名</label>
        <b-form-input id="input-live" v-model="floorName" placeholder="平面図名" trim></b-form-input>

      </div>
      <div class="mb-2">


        <label for="input-live">平面図ビットマップ</label>
        <b-form-file v-model="bitmapFile" accept=".png,.jpg,.jpeg" browse-text="選択"
          placeholder="平面図ビットマップファイルを 選択 または ドラッグ&ドロップ..." drop-placeholder="平面図ビットマップファイルをドロップ..."></b-form-file>
      </div>

      <div v-if="bitmapPreviewUrl != null">
        <img :src="bitmapPreviewUrl" width="300" />
      </div>

      <div class="d-flex">
        <b-button class="ml-auto" :disabled="!validInput" @click="onClickCreate">作成</b-button>
      </div>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header";
import Head from "@/mixins/Head";

export default {
  name: "FloorFigureList",
  components: { Header },
  mixins: [Head],
  head: {
    ...Head.head,
    title: {
      ...Head.head.title,
      inner: "平面図リスト",
    },
  },
  async mounted() {
    this.$store.dispatch("uiLock/incrementLoadingCount");
    try {
      // this.rawFloorFigureList = await this.$store.dispatch("zumen/getFloorFigureList", {})
    }
    finally {
      this.$store.dispatch("uiLock/decrementLoadingCount");
    }
  },
  data() {
    return {
      floorName: null,
      bitmapFile: null,
      bitmapPreviewUrl: null,
    };
  },
  computed: {
    validInput() {
      return typeof (this.floorName) == 'string' && this.floorName.trim().length > 0 && this.bitmapFile != null && this.bitmapPreviewUrl != null
    }
  },
  methods: {
    async onClickCreate() {
      this.$store.dispatch("uiLock/incrementLoadingCount");
      try {
        const { floorId } = await this.$store.dispatch("zumen/makeFloorFigure", {
          floorName: this.floorName,
          floorBitmapFile: this.bitmapFile,
        })

        this.$router.replace({ name: 'FloorFigure', params: { floorId: floorId } })
      }
      finally {
        this.$store.dispatch("uiLock/decrementLoadingCount");
      }
    }
  },
  watch: {
    bitmapFile() {
      this.bitmapPreviewUrl = URL.createObjectURL(this.bitmapFile)
    }
  }
};
</script>
