<template>
  <div class="home">
    <Header title="計測作成" />
    <b-container class="mt-2">

      <div class="mb-2">
        <label for="input-live">計測結果json</label>
        <b-form-file v-model="cameraJsonFile" accept=".json" browse-text="選択"
          placeholder="計測結果jsonファイルを 選択 または ドラッグ&ドロップ..." drop-placeholder="計測結果jsonファイルをドロップ..."></b-form-file>
      </div>

      <div class="d-flex">
        <b-button class="ml-auto" :disabled="!validInput" @click="onClickCreate">作成</b-button>
      </div>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header";
import Head from "@/mixins/Head";

export default {
  name: "MeasurementCreate",
  components: { Header },
  mixins: [Head],
  head: {
    ...Head.head,
    title: {
      ...Head.head.title,
      inner: "計測作成",
    },
  },
  async mounted() {
    this.$store.dispatch("uiLock/incrementLoadingCount");
    try {
      // this.rawFloorFigureList = await this.$store.dispatch("zumen/getFloorFigureList", {})
    }
    finally {
      this.$store.dispatch("uiLock/decrementLoadingCount");
    }
  },
  data() {
    return {
      floorName: null,
      cameraJsonFile: null,
    };
  },
  computed: {
    validInput() {
      return this.cameraJsonFile != null
    }
  },
  methods: {
    async onClickCreate() {
      this.$store.dispatch("uiLock/incrementLoadingCount");
      try {
        const { measurementId } = await this.$store.dispatch("zumen/makeMeasurement", {
          cameraJsonFile: this.cameraJsonFile,
        })

        this.$router.replace({ name: 'Measurement', params: { measurementId: measurementId } })
      }
      finally {
        this.$store.dispatch("uiLock/decrementLoadingCount");
      }
    }
  },
  watch: {
  }
};
</script>
