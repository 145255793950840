<template>
  <div class="home">
    <Header
      :title="`平面図 [${floorName ? floorName : '...'}] ${targetDevice ? ' (' + targetDevice.deviceCode + ')' : ''}`" />
    <b-container class="mt-2">

      <div class="mb-2 d-flex">
        <div v-if="problems.length > 0" class="mr-3">
          <template v-for="(problem, index) of problems">
            <div :key="index">{{ problem }}</div>
          </template>
        </div>
        <div>
          <b-button class="mr-4"
            :to="targetDevice ? { name: 'DeviceKokubanList', params: { floorId: floorId, deviceId: targetDevice.deviceId } } : { name: 'KokubanList', params: { floorId: floorId } }">黒板
            / 写真</b-button>
          <b-button class="mr-4" :to="{ name: 'FloorPhysicsAssociationList', params: { floorId: floorId } }">マーカー測量
          </b-button>
        </div>
        <div class="mx-auto">
          <template v-if="targetDevice">
            <b-button variant="light" @click="onClickResetForDemo">デモリセット</b-button>
          </template>
        </div>
        <div class="ml-auto text-right">
          <div class="display:inline-block;">
            <span class="text-secondary mr-2">
              <template v-if="autoReloadEnable">
                <template v-if="Number.isFinite(autoReloadRestSecond)">
                  {{ autoReloadRestSecond }}
                </template>
                <template v-else>
                  <b-spinner small></b-spinner>
                </template>
              </template>
            </span>
            <b-button class="py-0" size="sm" :disabled="isManualReloading" @click="onClickManualReload">
              <template v-if="isManualReloading">
                <b-spinner small></b-spinner>
              </template>
              <template v-else>
                更新
              </template>
            </b-button>
          </div>
          <b-form-checkbox v-model="autoReloadEnable" switch>自動</b-form-checkbox>
        </div>
      </div>

      <div style="position:relative">
        <DisplaySizeMeasure @resize="onResizeFloorFigureMeasure($event)"></DisplaySizeMeasure>
        <FloorFigureCanvas :floorId="floorId" :width="imageBoardWidth" :height="imageBoardHeight"
          :transfer="canvasTransfer">
          <template v-slot:default="p">
            <template v-if="canvasTransfer && Array.isArray(markers)">
              <template v-for="(m, mIndex) in markers">
                <g :key="mIndex">
                  <g
                    :transform="(m.meter ? p.transformFromMeter(m.meter.origin, m.meter.axisX) : p.transformFromPix(m.pixel.origin, m.pixel.axisX))">
                    <SvgMark kind="markerBoard"></SvgMark>
                  </g>
                </g>
              </template>
            </template>

            <template v-if="canvasTransfer && Array.isArray(visibleKokubans)">
              <template v-for="k in visibleKokubans">
                <g :key="`kmark_${k.createdAt} `">
                  <template v-if="'pos2' in k.location">
                    <g class="kokubanMark"
                      :transform="(k.definition.meter ? p.transformFromMeter : p.transformFromPix)(k.location.pos2.pos, { x: 1, y: 0 })"
                      @mouseover="onMouseoverKokuban(k.createdAt)" @mouseleave="onMouseleaveKokuban(k.createdAt)">
                      <SvgMark kind="kokubanCircle" :isHover="k.isHover"></SvgMark>
                    </g>
                  </template>
                  <template v-else-if="'pos3Normal' in k.location">
                    <g class="kokubanMark"
                      :transform="(k.definition.meter ? p.transformFromMeter : p.transformFromPix)(k.location.pos3Normal.pos, k.location.pos3Normal.normal)"
                      @mouseover="onMouseoverKokuban(k.createdAt)" @mouseleave="onMouseleaveKokuban(k.createdAt)">
                      <SvgMark kind="kokubanBoard" :isHover="k.isHover"></SvgMark>
                    </g>
                  </template>
                </g>
              </template>
            </template>

            <template v-if="canvasTransfer && Array.isArray(visiblePhotos)">
              <template v-for="ph in visiblePhotos">
                <g :key="`pmark_${ph.createdAt}`">
                  <template v-if="'pos2' in ph.location">
                    <g class="kokubanMark"
                      :transform="(ph.definition.meter ? p.transformFromMeter : p.transformFromPix)(ph.location.pos2.pos, { x: 1, y: 0 })"
                      @mouseover="onMouseoverPhoto(ph.createdAt)" @mouseleave="onMouseleavePhoto(ph.createdAt)">
                      <SvgMark kind="photoCircle" :isHover="ph.isHover"></SvgMark>
                    </g>
                  </template>
                  <template v-else-if="'pos3Normal' in ph.location">
                    <g class="kokubanMark"
                      :transform="(ph.definition.meter ? p.transformFromMeter : p.transformFromPix)(ph.location.pos3Normal.pos, ph.location.pos3Normal.normal)"
                      @mouseover="onMouseoverPhoto(ph.createdAt)" @mouseleave="onMouseleavePhoto(ph.createdAt)">
                      <SvgMark kind="photoBoard" :isHover="ph.isHover"></SvgMark>
                    </g>
                  </template>
                </g>
              </template>
            </template>


          </template>

        </FloorFigureCanvas>
        <div style="position:absolute; top:0px; left:0px; width:200px; opacity: 80%;">
          <template v-if="focusKokuban">
            <img :src="focusKokuban.url" width="100%" />
          </template>
          <template v-else-if="focusPhoto">
            <img :src="focusPhoto.url" width="100%" />
          </template>

        </div>

      </div>

      <div class="mt-3 py-2 d-flex">
        <div class="ml-0">
          <b-button class="mr-5" @click="onClickRemove">削除</b-button>
          <b-button class="mr-5" disabled>変更</b-button>
        </div>
      </div>

    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header";
import Head from "@/mixins/Head";
import FloorFigureCanvas from "@/components/FloorFigureCanvas";
import DisplaySizeMeasure from "@/components/DisplaySizeMeasure";
import SvgMark from "@/components/SvgMark";
import { difference } from "lodash"

export default {
  name: "FloorFigure",
  components: { Header, FloorFigureCanvas, DisplaySizeMeasure, SvgMark },
  mixins: [Head],
  head: {
    ...Head.head,
    title: {
      ...Head.head.title,
      inner: "平面図",
    },
  },
  props: ["floorId", "deviceId"],
  data() {
    return {
      targetDevice: null,
      floorName: null,
      problems: [],
      imageBoardTop: null, imageBoardWidth: null,
      innerWidth: null, innerHeight: null,

      canvasTransfer: null,
      markers: [],
      kokubans: [],
      focusKokuban: null,
      photos: [],
      focusPhoto: null,

      isManualReloading: false,
      autoReloadEnable: false,
      autoReloadTimerId: null,
      autoReloadRestSecond: null,
    };
  },
  computed: {
    imageBoardHeight() {
      if (Number.isFinite(this.innerHeight) && Number.isFinite(this.imageBoardTop)) {
        return this.innerHeight - this.imageBoardTop - 5
      } else {
        return 0
      }
    },
    autoReloadIntervalSecond() {
      return 30
    },

    visibleKokubans() {
      if (!Array.isArray(this.kokubans)) { return [] }
      return this.kokubans.filter(k => k.visible)
    },
    visiblePhotos() {
      if (!Array.isArray(this.photos)) { return [] }
      return this.photos.filter(p => p.visible)
    },
  },
  async mounted() {
    this.$store.dispatch("uiLock/incrementLoadingCount");
    try {
      this.handleResize()
      window.addEventListener('resize', this.handleResize)

      this.targetDevice = null
      if (this.deviceId) {
        const { devices } = await this.$store.dispatch("device/getDeviceList", {})
        this.targetDevice = devices.find(d => d.deviceId == this.deviceId)
      }

      const floorFigure = await this.$store.dispatch("zumen/getFloorFigure", { floorId: this.floorId })
      this.floorName = floorFigure.floorName
      const problems = []
      if (floorFigure.currentAssociationId == null) {
        problems.push('マーカー測量がありません')
      } else {
        const physicAssoc = await this.$store.dispatch("zumen/getPhysicsAssociation", { floorId: this.floorId, associationId: floorFigure.currentAssociationId })
        // console.log('physicAssoc', physicAssoc)
        this.canvasTransfer = ('pxFromMt' in physicAssoc.transfer) ? { pxFromMt: physicAssoc.transfer.pxFromMt } : { mtFromPx: physicAssoc.transfer.mtFromPx }
        this.markers = physicAssoc.markers.map(m => {
          const { pos, normal } = m.locationPos3Normal
          if ('pixel' in m.definition) {
            return {
              pixel: { origin: { x: pos.x, y: pos.y }, axisX: { x: normal.x, y: normal.y } }
            }
          } else if ('meter' in m.definition) {
            if (m.definition.meter.associationId == physicAssoc.associationId) {
              return {
                meter: { origin: { x: pos.x, y: pos.y }, axisX: { x: normal.x, y: normal.y } }
              }
            }
          }
          return null
        }).filter(i => i != null)

        await this.loadKokubanPhoto()
      }
      this.problems = problems

      this.setAutoReload(true)
    }
    finally {
      this.$store.dispatch("uiLock/decrementLoadingCount");
    }
  },
  beforeDestroy() {
    this.setAutoReload(false)
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    async loadKokubanPhoto() {
      await Promise.all([
        (async () => {
          const kokubans = await this.$store.dispatch("zumen/getKokubanList", { floorId: this.floorId, deviceId: this.targetDevice ? this.targetDevice.deviceId : null })
          if (!Array.isArray(this.kokubans)) {
            this.kokubans = []
          }
          const deletes = new Set(difference(this.kokubans.map(k => k.createdAt), kokubans.map(k => k.createdAt)))
          const adds = new Set(difference(kokubans.map(k => k.createdAt), this.kokubans.map(k => k.createdAt)))
          this.kokubans = this.kokubans.filter(k => !deletes.has(k.createdAt)).concat(
            kokubans.filter(k => adds.has(k.createdAt)).map(k => ({
              createdAt: k.createdAt,
              kokubanId: k.kokubanId,
              bitmapSizePx: { width: k.bitmapSizePx.width, height: k.bitmapSizePx.height, },
              location: k.location,
              definition: k.definition,
              url: k.kokubanBitmapUrl,
              visible: k.visible,
              isHover: false
            }))
          )
        })(),
        (async () => {
          const photos = await this.$store.dispatch("zumen/getPhotoList", { floorId: this.floorId, deviceId: this.targetDevice ? this.targetDevice.deviceId : null })
          if (!Array.isArray(this.photos)) {
            this.photos = []
          }
          const deletes = new Set(difference(this.photos.map(k => k.createdAt), photos.map(k => k.createdAt)))
          const adds = new Set(difference(photos.map(k => k.createdAt), this.photos.map(k => k.createdAt)))
          this.photos = this.photos.filter(k => !deletes.has(k.createdAt)).concat(
            photos.filter(k => adds.has(k.createdAt)).map(k => ({
              createdAt: k.createdAt,
              photoId: k.photoId,
              bitmapSizePx: { width: k.bitmapSizePx.width, height: k.bitmapSizePx.height, },
              location: k.location,
              definition: k.definition,
              url: k.photoBitmapUrl,
              visible: k.visible,
              isHover: false
            }))
          )
        })()
      ])
    },

    onResizeFloorFigureMeasure(e) {
      this.imageBoardTop = e.top
      this.imageBoardWidth = e.width
    },

    handleResize() {
      this.innerWidth = window.innerWidth;
      this.innerHeight = window.innerHeight;
    },

    onMouseoverKokuban(createdAt) {
      this.focusKokuban = this.kokubans.find(k => k.createdAt == createdAt)
    },
    onMouseleaveKokuban(createdAt) {
      this.focusKokuban = null
    },
    onMouseoverPhoto(createdAt) {
      this.focusPhoto = this.photos.find(k => k.createdAt == createdAt)
    },
    onMouseleavePhoto(createdAt) {
      this.focusPhoto = null
    },

    async onClickRemove() {
      this.$store.dispatch("uiLock/incrementLoadingCount");
      try {
        await this.$store.dispatch("zumen/removeFloorFigure", { floorId: this.floorId })

        this.$router.go(-1)
        this.$router.replace({ name: 'FloorFigureList' })
      }
      finally {
        this.$store.dispatch("uiLock/decrementLoadingCount");
      }
    },

    async onClickManualReload() {
      this.isManualReloading = true
      await this.loadKokubanPhoto()
      this.isManualReloading = false
    },

    setAutoReload(enable) {
      if (this.autoReloadEnable != (!!enable)) {
        this.autoReloadEnable = (!!enable)
      }
      if (this.autoReloadTimerId != null) {
        clearTimeout(this.autoReloadTimerId)
      }
      if (this.autoReloadEnable) {
        this.autoReloadRestSecond = this.autoReloadIntervalSecond
        this.autoReloadTimerId = setTimeout(this.onTimerAutoReload, 1000)
      } else {
        this.autoReloadRestSecond = null
      }
    },
    async onTimerAutoReload() {
      this.autoReloadRestSecond -= 1
      if (this.autoReloadRestSecond < 0) {
        this.autoReloadRestSecond = null
        await this.loadKokubanPhoto()
        this.autoReloadRestSecond = this.autoReloadIntervalSecond
      }
      this.autoReloadTimerId = setTimeout(this.onTimerAutoReload, 1000)
    },

    async onClickResetForDemo() {
      this.$store.dispatch("uiLock/incrementLoadingCount");
      try {
        await this.$store.dispatch("zumen/resetKokubanPhotoForDemo", { floorId: this.floorId, deviceId: this.targetDevice ? this.targetDevice.deviceId : null })
        await this.loadKokubanPhoto()
      }
      finally {
        this.$store.dispatch("uiLock/decrementLoadingCount");
      }
    }
  },
  watch: {
    autoReloadEnable() {
      this.setAutoReload(this.autoReloadEnable)
    }
  }
};
</script>
