<template>
  <div class="home">
    <Header :title="`平面図 [${floorName ? floorName : '...'}] - マーカー測量修正`" />
    <b-container class="mt-2 border">
      <b-row>
        <b-col class="pr-0" cols="4">
          <b-overlay :show="operation != null" rounded="sm"
            :style="{ height: `${floorFigureCanvasHeight}px`, overflowY: operation != null ? 'hidden' : 'auto' }">
            <div class="mb-4">
              <h4>対応点対</h4>
              <b-button variant="light" size="sm" @click="onClickStartInput1Pixel">図面で1Pixel入力</b-button>
              <b-button variant="light" size="sm" @click="onClickStartInput2Pixel">図面で2Pixel入力</b-button>
              <pre class="border">{{ pixelMeterPairYaml }}</pre>
            </div>

            <div class="mb-4">
              <h4>マーカー</h4>
              <b-button variant="light" size="sm" class="mr-2" @click="onClickStartEditMarker">変更</b-button>
              <b-button variant="light" size="sm" @click="onClickStartAddMarkerPos">図面でマーカー位置追加</b-button>
              <pre class="border">{{ markerYaml }}</pre>
            </div>


            <template #overlay>
              <div class="text-center">

                <div class="mb-3">図面をクリックしてください</div>

                <b-button ref="cancel" variant="outline-primary" size="sm" aria-describedby="cancel-label"
                  @click="onClickInputCancel">
                  キャンセル
                </b-button>
              </div>
            </template>
          </b-overlay>
        </b-col>

        <b-col cols="8" class="px-0">
          <DisplaySizeMeasure @resize="onResizeFloorFigureMeasure($event)"></DisplaySizeMeasure>
          <FloorFigureCanvas :floorId="floorId" :width="floorFigureCanvasWidth" :height="floorFigureCanvasHeight"
            :transfer="canvasTransfer" @click="onClickFloorFigure($event)" @mousemove="onMousemoveFloorFigure($event)">
            <template v-slot:default="p">
              <!-- マーカー定義 -->
              <g style="display:none">
                <g id="marker">
                  <line x1="-5" y1="-20" x2="-5" y2="20" stroke="white" stroke-width="6" stroke-linecap="round" />
                  <circle cx="0" cy="0" r="5" fill="none" stroke="white" stroke-width="6" />

                  <line x1=" -5" y1="-20" x2="-5" y2="20" stroke="darkorange" stroke-width="3" />
                  <circle cx="0" cy="0" r="5" fill="none" stroke="darkorange" stroke-width="3" />
                </g>
              </g>

              <template v-if="canvasTransfer && Array.isArray(markerInfo)">
                <template v-for="(m, mIndex) in markerInfo">
                  <g :key="mIndex">
                    <template v-if="m.meter">
                      <g
                        :transform="p.transformFromMeter({ x: m.meter.x, y: m.meter.y }, { x: m.meter.nx, y: m.meter.ny })">
                        <use href="#marker" />
                      </g>
                    </template>
                    <template v-else-if="m.pixel">
                      <g
                        :transform="p.transformFromPix({ x: m.pixel.x, y: m.pixel.y }, { x: m.pixel.nx, y: m.pixel.ny })">
                        <use href="#marker" />
                      </g>
                    </template>
                  </g>
                </template>
              </template>


              <template v-for="(pln, planeIndex) in planeRecords">
                <template v-for="(r, index) in pln.records">
                  <g :key="`p_${planeIndex}_${index}`">
                    <line :x1="p.posXFromMeter(r.pos1)" :y1="p.posYFromMeter(r.pos1)" :x2="p.posXFromMeter(r.pos2)"
                      :y2="p.posYFromMeter(r.pos2)" stroke="darkgreen" />
                  </g>
                </template>
              </template>

              <!-- <template v-for="(mark, markIndex) in markerRecords">
                <template v-for="(r, index) in mark.records">
                  <g :key="`m_${markIndex}_${index}`">
                    <g :transform="p.transformFromMeter(r.pos, r.normal)">
                      <line x1="-5" y1="-20" x2="-5" y2="20" stroke="white" stroke-width="6" stroke-linecap="round" />
                      <circle cx="0" cy="0" r="5" fill="none" stroke="white" stroke-width="6" />

                      <line x1=" -5" y1="-20" x2="-5" y2="20" stroke="darkorange" stroke-width="3" />
                      <circle cx="0" cy="0" r="5" fill="none" stroke="darkorange" stroke-width="3" />
                    </g>
                  </g>
                </template>
              </template> -->

              <template v-for="(cam, index) in cameraRecords">
                <g :key="`c_${index}`">
                  <g :transform="p.transformFromMeter(cam.pos, cam.normal)">
                    <circle cx="0" cy="0" r="2" :fill="`hsl(${cam.t * 300}, 100%, 50%)`" />
                  </g>
                </g>
              </template>





            </template>
          </FloorFigureCanvas>
          <div style="position:absolute; top:0px; left:0px; background-color: white; opacity: 80%;">
            <div>Pixel:({{ mousePixelX | num1 }}, {{ mousePixelY | num1 }})</div>
            <div>Meter:({{ mouseMeterX | num2 }}, {{ mouseMeterY | num2 }})</div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <div ref="footer" class="fixed-bottom">
      <b-container class="mt-2 py-2 bg-white shadow ">
        <div class="d-flex">
          <b-button class="" @click="onClickRemove">削除</b-button>

          <b-button class="ml-auto" @click="onClickCancel">キャンセル</b-button>
          <b-button class="ml-3" @click="onClickSave">保存</b-button>
        </div>
      </b-container>
    </div>
    <b-modal v-model="displayModalEditYaml" :title="modalEditYamlTitle"
      :ok-disabled="modalEditYamlError === null ? false : true" @ok="onOkModalEditYaml">
      <b-form-textarea v-model="modalEditYamlInput" max-rows="20" :state="modalEditYamlError === null ? null : false"
        @input="onModalEditYamlInputChange">
      </b-form-textarea>
      <template v-if="modalEditYamlError != null">
        <pre>{{ modalEditYamlError }}</pre>
      </template>

    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header";
import Head from "@/mixins/Head";
import FloorFigureCanvas from "@/components/FloorFigureCanvas";
import DisplaySizeMeasure from "@/components/DisplaySizeMeasure";
import yaml from "js-yaml";
import v8n from "v8n";
import numeral from "numeral"
import { zip as lodashZip } from "lodash"

export default {
  name: "FloorPhysicsAssociationMeasureEdit",
  components: { Header, FloorFigureCanvas, DisplaySizeMeasure },
  mixins: [Head],
  head: {
    ...Head.head,
    title: {
      ...Head.head.title,
      inner: "マーカー測量",
    },
  },
  props: ["floorId", "associationId"],
  async mounted() {
    this.$store.dispatch("uiLock/incrementLoadingCount");
    try {
      this.onWindowResize()
      window.addEventListener('resize', this.onWindowResize)

      this.footerHeight = this.$refs.footer.clientHeight

      const [floorFigure, physicAssoc] = await Promise.all([
        (async () => {
          const floorFigure = await this.$store.dispatch("zumen/getFloorFigure", { floorId: this.floorId })
          return floorFigure
        })(),
        (async () => {
          const physicAssoc = await this.$store.dispatch("zumen/getPhysicsAssociation", { floorId: this.floorId, associationId: this.associationId })
          return physicAssoc
        })(),
      ])

      this.floorName = floorFigure.floorName

      if (physicAssoc.editorInfo.measurement.pixelMeterPair) {
        this.setPixelMeterPair(physicAssoc.editorInfo.measurement.pixelMeterPair)
      }

      const markers = [].concat(
        physicAssoc.markers.map(m => {
          const match = m.markerId.match(/^arUco4x4_(?<id>\d+)$/)
          const markerId = match ? parseInt(match.groups.id) : m.markerId

          if (m.locationPos3Normal && m.definition.meter && m.definition.meter.associationId == physicAssoc.associationId) {
            const pos = m.locationPos3Normal.pos
            const n = m.locationPos3Normal.normal
            return {
              id: markerId,
              meter: {
                x: pos.x, y: pos.y, z: pos.z,
                nx: n.x, ny: n.y
              }
            }
          } else if (m.locationPos3Normal && m.definition.pixel) {
            const pos = m.locationPos3Normal.pos
            const n = m.locationPos3Normal.normal
            return {
              id: markerId,
              pixel: {
                x: pos.x, y: pos.y,
                nx: n.x, ny: n.y
              },
              meterZ: pos.z,
            }
          }
          return null
        }),
        (physicAssoc.editorInfo?.measurement?.invalidMarkers ?? [])
      )
      this.setMarkerInfo(markers)

      const {
        measurement,
        cameraRecords, markerRecords, planeRecords
      } = await this.$store.dispatch("zumen/getMeasurement", { measurementId: physicAssoc.measurementId })

      this.plainBound = measurement.plainBound
      this.timeBound = measurement.timeBound
      this.rawCameraRecords = cameraRecords
      // this.rawMarkerRecords = markerRecords
      this.rawPlaneRecords = planeRecords

    }
    finally {
      this.$store.dispatch("uiLock/decrementLoadingCount");
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onWindowResize)
  },
  filters: {
    num1(v) {
      if (!Number.isFinite(v)) { return null }
      return numeral(v).format('+0.0')
    },
    num2(v) {
      if (!Number.isFinite(v)) { return null }
      return numeral(v).format('+0.00')
    }
  },
  data() {
    return {
      floorName: null,
      innerWidth: null, innerHeight: null,
      floorFigureCanvasTop: null, floorFigureCanvasWidth: null,
      footerHeight: null,

      mousePixelX: null, mousePixelY: null,
      mouseMeterX: null, mouseMeterY: null,

      plainBound: null, timeBound: null,
      rawCameraRecords: null, /*rawMarkerRecords: null,*/ rawPlaneRecords: null,

      pixelMeterPair: null, pixelMeterPairYaml: null,
      markerInfo: null, markerYaml: null,

      operation: null,
      displayModalEditYaml: false,
      modalEditYamlTitle: null,
      modalEditYamlOperation: null,
      modalEditYamlInput: null,
      modalEditYamlError: null,
    };
  },
  computed: {
    floorFigureCanvasHeight() {
      if (Number.isFinite(this.innerHeight) && Number.isFinite(this.floorFigureCanvasTop) && Number.isFinite(this.footerHeight)) {
        return this.innerHeight - this.floorFigureCanvasTop - this.footerHeight
      } else {
        return 0
      }
    },


    transferPxFromMt() {
      if (this.pixelMeterPair) {
        const { pos1: { meter: p1m, pixel: p1p }, pos2: { meter: p2m, pixel: p2p } } = this.pixelMeterPair
        //  A:pos1→pos2に向かうベクトル
        const sub = (v1, v2) => ({ x: v1.x - v2.x, y: v1.y - v2.y })
        const am = sub(p2m, p1m), ap = sub(p2p, p1p)
        //  B:Aと直行するベクトル。座標系の向きが異なるので符号が異なる
        const bm = { x: -am.y, y: am.x }, bp = { x: ap.y, y: -ap.x }

        const p00 = ap.x, p01 = bp.x
        const p10 = ap.y, p11 = bp.y
        const m00 = am.x, m01 = bm.x
        const m10 = am.y, m11 = bm.y
        const detM = m00 * m11 - m01 * m10
        const invDetM = 1.0 / detM
        const im00 = m11 * invDetM, im01 = -m01 * invDetM
        const im10 = -m10 * invDetM, im11 = m00 * invDetM
        const pInvM00 = (p00 * im00 + p01 * im10), pInvM01 = (p00 * im01 + p01 * im11)
        const pInvM10 = (p10 * im00 + p11 * im10), pInvM11 = (p10 * im01 + p11 * im11)

        return {
          rowX: { x: pInvM00, y: pInvM01, z: 0 }, tX: p1p.x - (pInvM00 * p1m.x + pInvM01 * p1m.y),
          rowY: { x: pInvM10, y: pInvM11, z: 0 }, tY: p1p.y - (pInvM10 * p1m.x + pInvM11 * p1m.y),
          rowZ: { x: 0, y: 0, z: 1 }, tZ: 0,
        }
      } else {
        return null
      }
    },
    canvasTransfer() {
      const tx = this.transferPxFromMt
      if (tx) {
        return {
          pxFromMt: {
            rowX: { x: tx.rowX.x, y: tx.rowX.y }, tX: tx.tX,
            rowY: { x: tx.rowY.x, y: tx.rowY.y }, tY: tx.tY,
          }
        }
      } else {
        return null
      }
    },

    transferTFromTime() {
      if (!(
        Number.isFinite(this.timeBound?.min) && Number.isFinite(this.timeBound?.max))) {
        return null
      }
      const scale = 1.0 / (this.timeBound.max - this.timeBound.min)
      return {
        scale: scale,
        c: -scale * this.timeBound.min
      }
    },
    cameraRecords() {
      if (!(this.transferTFromTime && Array.isArray(this.rawCameraRecords))) { return [] }

      const tFromTime = (() => {
        const { scale, c } = this.transferTFromTime
        return (time) => (time * scale + c)
      })()

      return this.rawCameraRecords.map(r => ({
        t: tFromTime(r.time),
        pos: r.pos,
        normal: r.normal,
      }))
    },
    markerRecords() {
      if (!(Array.isArray(this.rawMarkerRecords))) { return [] }

      return this.rawMarkerRecords.map(m => ({
        id: m.id,
        records: m.records.map(r => ({
          time: r.time,
          pos: r.pos,
          normal: r.normal,
        }))
      }))
    },
    planeRecords() {
      if (!(Array.isArray(this.rawPlaneRecords))) { return [] }

      return this.rawPlaneRecords.map(m => ({
        id: m.id,
        records: m.records.map(r => {
          const norm = Math.sqrt(r.normal.x * r.normal.x + r.normal.y * r.normal.y)
          const nx = r.normal.x / norm, ny = r.normal.y / norm
          return {
            time: r.time,
            pos: r.pos,
            pos1: { x: r.pos.x + ny * r.height * 0.5, y: r.pos.y - nx * r.height * 0.5, },
            pos2: { x: r.pos.x - ny * r.height * 0.5, y: r.pos.y + nx * r.height * 0.5, },
          }
        })
      }))
    }

  },
  methods: {
    onWindowResize() {
      this.innerWidth = window.innerWidth;
      this.innerHeight = window.innerHeight;
    },
    onResizeFloorFigureMeasure(e) {
      this.floorFigureCanvasTop = e.top
      this.floorFigureCanvasWidth = e.width
    },

    onMousemoveFloorFigure(e) {
      const { imagePixelX, imagePixelY, meterX, meterY } = e
      this.mousePixelX = imagePixelX
      this.mousePixelY = imagePixelY
      this.mouseMeterX = meterX
      this.mouseMeterY = meterY
      if (this.operation != null && typeof (this.operation.onMove) == 'function') {
        this.operation.onMove({ imagePixelX, imagePixelY })
      }
    },

    onClickFloorFigure(e) {
      const { imagePixelX, imagePixelY } = e
      if (this.operation != null && typeof (this.operation.onClick) == 'function') {
        const isDone = this.operation.onClick({ imagePixelX, imagePixelY })
        if (isDone) {
          this.operation = null
        }
      } else {
        this.operation = null
      }
    },
    onClickInputCancel() {
      if (this.operation != null && typeof (this.operation.onCancel) == 'function') {
        this.operation.onCancel()
      }
      this.operation = null
    },

    onModalEditYamlInputChange() {
      try {
        const info = yaml.load(this.modalEditYamlInput)
        if (typeof (this.modalEditYamlOperation?.checkError) == 'function') {
          this.modalEditYamlError = this.modalEditYamlOperation.checkError(info)
        } else {
          this.modalEditYamlError = '????'
        }
      } catch (error) {
        if (error?.mark?.snippet && error?.reason) {
          this.modalEditYamlError = error?.reason + "\n" + error?.mark?.snippet
        } else {
          this.modalEditYamlError = error.toString()
        }
      }
    },
    onOkModalEditYaml(e) {
      console.log('onOkModalEditYaml')
      if (!(typeof (this.modalEditYamlOperation?.checkError) == 'function' && typeof (this.modalEditYamlOperation?.save) == 'function')) {
        throw new Error('modalEditYamlOperation 設定エラー')
      }
      const info = (() => {
        try {
          return yaml.load(this.modalEditYamlInput)
        }
        catch (error) {
          return null
        }
      })()
      if (info != null && this.modalEditYamlOperation.checkError(info) == null) {
        this.modalEditYamlOperation.save(info)
      } else {
        e.preventDefault()
      }
    },

    setPixelMeterPair(pixelMeterPair) {
      v8n().schema({
        pos1: v8n().schema({
          meter: v8n().schema({ x: v8n().number(), y: v8n().number() }),
          pixel: v8n().schema({ x: v8n().number(), y: v8n().number() }),
        }),
        pos2: v8n().schema({
          meter: v8n().schema({ x: v8n().number(), y: v8n().number() }),
          pixel: v8n().schema({ x: v8n().number(), y: v8n().number() }),
        })
      }).check(pixelMeterPair)
      const getVec2 = (v) => ({ x: v.x, y: v.y })
      const getPixelMeter = (mp) => ({
        meter: getVec2(mp.meter),
        pixel: getVec2(mp.pixel),
      })
      this.pixelMeterPair = {
        pos1: getPixelMeter(pixelMeterPair.pos1),
        pos2: getPixelMeter(pixelMeterPair.pos2),
      }
      this.pixelMeterPairYaml = yaml.dump({ pixelMeterPair: this.pixelMeterPair })
    },

    onClickStartInput1Pixel() {
      const startPixelMeterPair = { ...this.pixelMeterPair }
      this.operation = {
        onClick: ({ imagePixelX, imagePixelY }) => {
          this.setPixelMeterPair({
            pos1: {
              meter: this.pixelMeterPair.pos1.meter,
              pixel: { x: imagePixelX, y: imagePixelY },
            },
            pos2: this.pixelMeterPair.pos2,
          })
          return true
        },
        onMove: ({ imagePixelX, imagePixelY }) => {
          this.setPixelMeterPair({
            pos1: {
              meter: this.pixelMeterPair.pos1.meter,
              pixel: { x: imagePixelX, y: imagePixelY },
            },
            pos2: this.pixelMeterPair.pos2,
          })
        },
        onCancel: () => {
          this.setPixelMeterPair(startPixelMeterPair)
        },
      }
    },
    onClickStartInput2Pixel() {
      const startPixelMeterPair = { ...this.pixelMeterPair }
      this.operation = {
        onClick: ({ imagePixelX, imagePixelY }) => {
          this.setPixelMeterPair({
            pos1: this.pixelMeterPair.pos1,
            pos2: {
              meter: this.pixelMeterPair.pos2.meter,
              pixel: { x: imagePixelX, y: imagePixelY },
            },
          })
          return true
        },
        onMove: ({ imagePixelX, imagePixelY }) => {
          this.setPixelMeterPair({
            pos1: this.pixelMeterPair.pos1,
            pos2: {
              meter: this.pixelMeterPair.pos2.meter,
              pixel: { x: imagePixelX, y: imagePixelY },
            },
          })
        },
        onCancel: () => {
          this.setPixelMeterPair(startPixelMeterPair)
        },
      }
    },

    onClickStartEditMarker() {
      this.modalEditYamlInput = this.markerYaml
      this.modalEditYamlError = null
      this.modalEditYamlOperation = {
        checkError: (info) => {
          console.log('info', info)
          const invalidSchema = {
            pixel: v8n().undefined(), meterZ: v8n().undefined(),
            meter: v8n().undefined(),
          }
          if (!v8n().schema({
            markers: v8n().array()
              .every.schema({
                id: v8n().not.undefined()
              })
              .every.passesAnyOf(
                v8n().schema({
                  ...invalidSchema,
                  pixel: v8n().schema({
                    x: v8n().number(), y: v8n().number(),
                    nx: v8n().number(), ny: v8n().number(),
                  }),
                  meterZ: v8n().number(),
                }),
                v8n().schema({
                  ...invalidSchema,
                  meter: v8n().schema({
                    x: v8n().number(), y: v8n().number(), z: v8n().number(),
                    nx: v8n().number(), ny: v8n().number(),
                  }),
                }),
              )
          }).test(info)) {
            return [
              'markers:Array<{',
              ' id: number,',
              ' pixel:{ x:number, y:number, nx:number, ny:number }, ',
              ' meterZ:number',
              '} | {',
              ' id: number,',
              ' meter:{ x:number, y:number, z:number, nx:number, ny:number }',
              '}>',
              'が必要です。'
            ].join("\n")
          }
          return null
        },
        save: (info) => {
          this.setMarkerInfo(info.markers)
        }
      }
      this.modalEditYamlTitle = 'マーカー'
      this.displayModalEditYaml = true
    },
    setMarkerInfo(markerInfo) {

      const checkedMarkers = []
      for (const m of markerInfo) {
        const errors = []
        if (!(Number.isSafeInteger(m.id) && m.id >= 0)) {
          errors.push('id不正')
        }
        if (m.meter) {
          const norm2 = m.meter.nx * m.meter.nx + m.meter.ny * m.meter.ny
          if (norm2 < 1e-3) { errors.push('方向なし') }
        } else if (m.pixel) {
          const norm2 = m.pixel.nx * m.pixel.nx + m.pixel.ny * m.pixel.ny
          if (norm2 < 1e-3) { errors.push('方向なし') }
        }
        if (errors.length == 0 && checkedMarkers.filter(m => (m?.errors ?? []).length == 0).some(vm => vm.id == m.id)) {
          errors.push(`id重複`)
        }

        const marker = (() => {
          if (m.meter) {
            return {
              id: m.id,
              meter: {
                x: m.meter.x, y: m.meter.y, z: m.meter.z,
                nx: m.meter.nx, ny: m.meter.ny,
              }
            }
          } else if (m.pixel) {
            return {
              id: m.id,
              pixel: {
                x: m.pixel.x, y: m.pixel.y,
                nx: m.pixel.nx, ny: m.pixel.ny,
              },
              meterZ: m.meterZ
            }
          } else {
            throw new Error(`invalid marker [${JSON.stringify(m, null, 2)}]`)
          }
        })()
        checkedMarkers.push({
          ...marker,
          ...(errors.length == 0 ? { errors: (void 0) } : { errors })
        })
      }
      this.markerInfo = checkedMarkers
      this.markerYaml = yaml.dump({ markers: this.markerInfo })
    },

    onClickStartAddMarkerPos() {
      this.operation = {
        onClick: ({ imagePixelX, imagePixelY }) => {
          this.setMarkerInfo(
            this.markerInfo.concat([{
              id: `入力中 #${this.markerInfo.length + 1}`,
              pixel: {
                x: Math.round(imagePixelX * 10) / 10,
                y: Math.round(imagePixelY * 10) / 10,
                nx: 1, ny: 0
              },
              meterZ: 1.0
            }])
          )
          return true
        }
      }
    },

    onClickCancel() {
      this.$router.go(-1);
    },
    async onClickSave() {
      this.$store.dispatch("uiLock/incrementLoadingCount");
      try {
        await this.$store.dispatch("zumen/modifyPhysicsAssociationMeasurement", {
          floorId: this.floorId,
          associationId: this.associationId,
          pixelMeterPair: this.pixelMeterPair,
          transferPxFromMt: this.transferPxFromMt,
          markers: this.markerInfo.filter(m => (m?.errors ?? []).length == 0),
          invalidMarkers: this.markerInfo.filter(m => (m?.errors ?? []).length != 0),
        })

        this.$router.go(-1);
      }
      finally {
        this.$store.dispatch("uiLock/decrementLoadingCount");
      }
    },

    async onClickRemove() {
      this.$store.dispatch("uiLock/incrementLoadingCount");
      try {
        await this.$store.dispatch("zumen/removePhysicsAssociation", {
          floorId: this.floorId,
          associationId: this.associationId,
        })

        this.$router.go(-1);
        this.$router.replace({ name: 'FloorPhysicsAssociationList', params: { floorId: this.floorId } })
      }
      finally {
        this.$store.dispatch("uiLock/decrementLoadingCount");
      }

    }
  },
};
</script>
