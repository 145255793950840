<template>
  <div v-if="Number.isFinite(width) && Number.isFinite(height)">
    <svg class="px-0 mx-0 border" :style="{ width: `${width}px`, height: `${height}px` }"
      :viewPort="`0 0 ${width} ${height}`" :viewBox="`${viewBoxLeft} ${viewBoxTop} ${width} ${height}`" @click="onClick"
      @mousemove="onMouseMove">

      <image x="0" y="0" :width="floorImageDisplaySize.width" :height="floorImageDisplaySize.height"
        :href="floorImageUrl" @load="onLoadImage"></image>

      <slot name="default" v-bind:posXyFromMeter="posXyFromMeter" v-bind:posXFromMeter="posXFromMeter"
        v-bind:posYFromMeter="posYFromMeter" v-bind:vecXyFromMeter="vecXyFromMeter"
        v-bind:transformFromMeter="transformFromMeter" v-bind:posXyFromPix="posXyFromPix"
        v-bind:vecXyFromPix="vecXyFromPix" v-bind:transformFromPix="transformFromPix">
      </slot>
    </svg>
  </div>
</template>
<script>

export default {
  name: "FloorFigureCanvas",
  components: {},
  props: [
    //  表示ピクセルサイズ
    "width", "height",
    //  ビットマップを特定するための floorId
    "floorId",
    //  変換
    /**
     * @type {
     *  originAxis ?: {
     *    origin: {x:number, y:number},
     *    axisX: {x:number, y:number, meter:number},
     *    axisY: {x:number, y:number, meter:number},
     *  },
     *  pxFromMt ?: {
     *    rowX: { x:number, y:number }, tX:number,
     *    rowY: { x:number, y:number }, tY:number,
     *  },
     *  mtFromPx ?: {},
     * }
     */
    "transfer",
  ],
  data() {
    return {
      floorName: null,

      floorImageUrl: null,
      floorImageWidth: null,
      floorImageHeight: null,
    };
  },
  computed: {
    viewBoxLeft() {
      if (Number.isFinite(this.width)) {
        return (this.floorImageDisplaySize.width - this.width) / 2
      } else {
        return 0
      }
    },
    viewBoxTop() {
      if (Number.isFinite(this.height)) {
        return (this.floorImageDisplaySize.height - this.height) / 2
      } else {
        return 0
      }
    },
    floorImageDisplaySize() {
      if (Number.isFinite(this.width) && Number.isFinite(this.height) && Number.isFinite(this.floorImageWidth) && Number.isFinite(this.floorImageHeight)
        && Math.min(this.width, this.height, this.floorImageWidth, this.floorImageHeight) > 0) {
        if (this.width * this.floorImageHeight < this.floorImageWidth * this.height) {
          return {
            height: this.width * this.floorImageHeight / this.floorImageWidth,
            width: this.width
          }
        } else {
          return {
            width: this.height * this.floorImageWidth / this.floorImageHeight,
            height: this.height
          }
        }

      } else {
        return { width: 0, height: 0 }
      }
    },

    posXFromMeter() {
      const canvasXyFromMeter = this.canvasXyFromMeter
      return (meter) => canvasXyFromMeter(true/*isPositionVector*/, meter).x
    },
    posYFromMeter() {
      const canvasXyFromMeter = this.canvasXyFromMeter
      return (meter) => canvasXyFromMeter(true/*isPositionVector*/, meter).y
    },
    posXyFromMeter() {
      const canvasXyFromMeter = this.canvasXyFromMeter
      return (meter) => canvasXyFromMeter(true/*isPositionVector*/, meter)
    },
    vecXyFromMeter() {
      const canvasXyFromMeter = this.canvasXyFromMeter
      return (meter) => canvasXyFromMeter(false/*isPositionVector*/, meter)
    },
    canvasXyFromMeter() {
      const { width: dispW, height: dispH } = this.floorImageDisplaySize
      const scaleX = dispW / this.floorImageWidth, scaleY = dispH / this.floorImageHeight

      if (this.transfer && 'originAxis' in this.transfer) {
        const { origin, axisX, axisY } = this.transfer.originAxis
        const tx = origin.x, ty = origin.y
        const vecXx = axisX.x / axisX.meter, vecXy = axisX.y / axisX.meter
        const vecYx = axisY.x / axisY.meter, vecYy = axisY.y / axisY.meter
        return (isPositionVector, meter) => {
          return {
            x: (meter.x * vecXx + meter.y * vecYx + (isPositionVector ? tx : 0)) * scaleX,
            y: (meter.x * vecXy + meter.y * vecYy + (isPositionVector ? ty : 0)) * scaleY,
          }
        }
      } else if (this.transfer && 'pxFromMt' in this.transfer) {
        const { rowX, tX, rowY, tY } = this.transfer.pxFromMt
        return (isPositionVector, meter) => {
          return {
            x: (meter.x * rowX.x + meter.y * rowY.x + (isPositionVector ? tX : 0)) * scaleX,
            y: (meter.x * rowX.y + meter.y * rowY.y + (isPositionVector ? tY : 0)) * scaleY,
          }
        }

      } else {
        return (isPositionVector, meter) => {
          return { x: 0, y: 0 }
        }
      }
    },

    posMeterXyFromPix() {
      const meterXyFromPix = this.meterXyFromPix
      return (pix) => meterXyFromPix(true/*isPositionVector*/, pix)
    },
    meterXyFromPix() {
      if (this.transfer && 'originAxis' in this.transfer) {
        const { origin, axisX, axisY } = this.transfer.originAxis
        const tx = origin.x, ty = origin.y
        const vecXx = axisX.x / axisX.meter, vecXy = axisX.y / axisX.meter
        const vecYx = axisY.x / axisY.meter, vecYy = axisY.y / axisY.meter
        const det = vecXx * vecYy - vecXy * vecYx
        return (isPositionVector, pix) => {
          const x = isPositionVector ? (pix.x - tx) : pix.x
          const y = isPositionVector ? (pix.y - ty) : pix.y
          return {
            x: (x * vecYy - y * vecYx) / det,
            y: (-x * vecXy + y * vecXx) / det,
          }
        }
      } else if (this.transfer && 'pxFromMt' in this.transfer) {
        const { rowX, tX, rowY, tY } = this.transfer.pxFromMt
        const det = rowX.x * rowY.y - rowX.y * rowY.x

        const invXx = rowY.y / det, invYx = -rowY.x / det
        const invXy = -rowX.y / det, invYy = rowX.x / det

        const invTx = -(tX * invXx + tY * invYx)
        const invTy = -(tX * invXy + tY * invYy)

        return (isPositionVector, pix) => {
          return {
            x: (pix.x * invXx + pix.y * invYx + (isPositionVector ? invTx : 0)),
            y: (pix.x * invXy + pix.y * invYy + (isPositionVector ? invTy : 0)),
          }
        }

      } else {
        return (isPositionVector, pix) => {
          return { x: 0, y: 0 }
        }
      }
    },


    transformFromMeter() {
      return (origin, axisX) => {
        const { x: orgX, y: orgY } = this.posXyFromMeter(origin)
        const { xx, xy } = (() => {
          const { x, y } = this.vecXyFromMeter(axisX)
          const norm = Math.sqrt(x * x + y * y)
          if (norm < 1e-3) { return { xx: 1, xy: 0 } }
          return { xx: x / norm, xy: y / norm }
        })()
        return `matrix(${xx} ${xy} ${-xy} ${xx} ${orgX} ${orgY})`
      }
    },

    posXyFromPix() {
      const canvasXyFromPix = this.canvasXyFromPix
      return (meter) => canvasXyFromPix(true/*isPositionVector*/, meter)
    },
    vecXyFromPix() {
      const canvasXyFromPix = this.canvasXyFromPix
      return (meter) => canvasXyFromPix(false/*isPositionVector*/, meter)
    },
    canvasXyFromPix() {
      const { width: dispW, height: dispH } = this.floorImageDisplaySize
      const scaleX = dispW / this.floorImageWidth, scaleY = dispH / this.floorImageHeight

      if (this.transfer && 'originAxis' in this.transfer) {
        return (isPositionVector, pix) => {
          return {
            x: pix.x * scaleX,
            y: pix.y * scaleY,
          }
        }
      } else if (this.transfer && 'pxFromMt' in this.transfer) {
        return (isPositionVector, pix) => {
          return {
            x: pix.x * scaleX,
            y: pix.y * scaleY,
          }
        }
      } else {
        return (isPositionVector, pix) => {
          return { x: 0, y: 0 }
        }
      }
    },
    transformFromPix() {
      return (origin, axisX) => {
        const { x: orgX, y: orgY } = this.posXyFromPix(origin)
        const { xx, xy } = (() => {
          const { x, y } = this.vecXyFromPix(axisX)
          const norm = Math.sqrt(x * x + y * y)
          if (norm < 1e-3) { return { xx: 1, xy: 0 } }
          return { xx: x / norm, xy: y / norm }
        })()
        return `matrix(${xx} ${xy} ${-xy} ${xx} ${orgX} ${orgY})`
      }
    },

  },
  async mounted() {
    this.$store.dispatch("uiLock/incrementLoadingCount");
    try {
      const [floorFigure, floorImageUrl] = await Promise.all([
        (async () => {
          return await this.$store.dispatch("zumen/getFloorFigure", { floorId: this.floorId })
        })(),
        (async () => {
          return await this.$store.dispatch("zumen/getFloorFigureBitmapUrl", { floorId: this.floorId })
        })(),
      ])

      this.floorName = floorFigure.floorName
      this.floorImageWidth = floorFigure.figureSizePx.width
      this.floorImageHeight = floorFigure.figureSizePx.height

      this.$store.dispatch("uiLock/incrementLoadingCount");
      this.floorImageUrl = floorImageUrl

    } finally {
      this.$store.dispatch("uiLock/decrementLoadingCount");
    }
  },
  beforeDestroy() {
  },
  methods: {
    onLoadImage(e) {
      this.$store.dispatch("uiLock/decrementLoadingCount");
    },
    onClick(e) {
      if (Math.min(this.floorImageDisplaySize.width, this.floorImageDisplaySize.height) > 0) {
        const imagePixelX = (e.offsetX + this.viewBoxLeft) * this.floorImageWidth / this.floorImageDisplaySize.width
        const imagePixelY = (e.offsetY + this.viewBoxTop) * this.floorImageHeight / this.floorImageDisplaySize.height
        const meter = this.posMeterXyFromPix({ x: imagePixelX, y: imagePixelY })
        this.$emit('click', { imagePixelX, imagePixelY, meterX: meter.x, meterY: meter.y })
      }
    },
    onMouseMove(e) {
      if (Math.min(this.floorImageDisplaySize.width, this.floorImageDisplaySize.height) > 0) {
        const imagePixelX = (e.offsetX + this.viewBoxLeft) * this.floorImageWidth / this.floorImageDisplaySize.width
        const imagePixelY = (e.offsetY + this.viewBoxTop) * this.floorImageHeight / this.floorImageDisplaySize.height
        const meter = this.posMeterXyFromPix({ x: imagePixelX, y: imagePixelY })
        this.$emit('mousemove', { imagePixelX, imagePixelY, meterX: meter.x, meterY: meter.y })
      }

    }
  },
};
</script>
