<template>
  <b-navbar
    toggleable="lg"
    type="dark"
    style="background-color: #1a2274"
    sticky
  >
    <b-navbar-nav>
      <b-nav-item @click="goBack">
        <font-awesome-icon size="lg" :icon="['fa', 'chevron-circle-left']" />
      </b-nav-item>
    </b-navbar-nav>
    <b-navbar-nav>
      <b-nav-text class="text-white py-0 my-0" style="font-size: 1.2rem">{{
        title
      }}</b-nav-text>
    </b-navbar-nav>
    <!-- <b-navbar-nav>
        <b-nav-item href="#" :active="true" link-classes="border rounded-sm">{{
          "何か"
        }}</b-nav-item>
      </b-navbar-nav> -->
    <b-navbar-nav class="ml-auto">
      <b-nav-item-dropdown right>
        <template #button-content>
          {{ username }}
        </template>
        <b-dropdown-item href="#" @click="logout">ログアウト</b-dropdown-item>
      </b-nav-item-dropdown>
      <b-navbar-brand :to="{ name: 'Top' }"
        ><img src="@/assets/icons/logo_white.svg" alt="蔵衛門ロゴ"
      /></b-navbar-brand>
      <b-nav-text v-if="menuEnvironment" class="bg-info text-white">{{
        menuEnvironment
      }}</b-nav-text>
    </b-navbar-nav>
  </b-navbar>
</template>
<script>
import { mapGetters } from "vuex";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons";
library.add(faChevronCircleLeft);

export default {
  name: "Header",
  props: ["title"],
  data() {
    return {};
  },
  computed: {
    ...mapGetters("api", ["username", "isAdmin"]),
    menuEnvironment() {
      return process.env?.VUE_APP_MENU_ENVIRONMENT;
    },
  },

  async mounted() {},

  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async logout() {
      await this.$store.dispatch("api/logout");
      this.$router.go(-100);
      this.$router.replace({ name: "Top" });
    },
  },
  watch: {},
};
</script>
