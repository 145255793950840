import app from './app'
import uiLock from './uiLock'
import api from './api'
import zumen from './zumen'
import device from './device'

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app, uiLock,
    api,
    zumen, device,
  },
  state: {
  },
  mutations: {
  },
  actions: {
  },
  plugins: [
  ]
})
